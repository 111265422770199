import '../styles/popout.scss';

export default function Popup({ children, showPopup, closePopup }) {
    return (
        <div className={`confirm-box ${showPopup ? 'act' : 'leave'} notitle`}>
            <div className="bd1-b">{children}</div>
            <p className="bts d-flex ai-center jc-center">
                <span
                    className="btn2 b2"
                    style={{ margin: '20px' }}
                    onClick={closePopup}
                >
                    關閉
                </span>
            </p>
        </div>
    );
}
