import './styles/backstage.scss';

export default function Explanation() {
    return (
        <div className="main-content">
            <div className="explanation">
                <div>
                    <h1>Server ID 與 Role ID 設定</h1>
                    <div className="inner">
                        <p className="bd1-n">
                            為了串連您項目的Discord，我們需要您提供相關
                            ID資訊，步驟如下：
                        </p>
                        <h5 className="bd2-b">1. 開啟『開發者模式』</h5>
                        <p className="bd1-n">
                            路徑：使用者設定＞應用程式設定＞進階＞開發者模式＞開啟
                        </p>
                        <img
                            src="/images/marketBackstage/explain_1.png"
                            alt="note"
                        />

                        <h5 className="bd2-b">2. 複製 Server ID</h5>
                        <p className="bd1-n">
                            右鍵點擊Server名稱，即可看見 複製ID 選項
                        </p>
                        <img
                            src="/images/marketBackstage/explain_2.png"
                            alt="note"
                        />

                        <h5 className="bd2-b">3. 複製 Role ID</h5>
                        <p className="bd1-n">
                            開啟持有指定Role的人物資訊，右鍵點擊Role，即可看見
                            複製ID選項
                        </p>
                        <img
                            src="/images/marketBackstage/explain_3.png"
                            alt="note"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
