import '../styles/popout.scss';

export default function Confirm({
    title,
    deleteProjectId,
    setDeleteProjectId,
    deleteProject,
}) {
    return (
        // 加 'act' 顯示
        <div className={`confirm-box ${deleteProjectId ? 'act' : 'leave'}`}>
            <p className="p1 bd1-b">{title}</p>
            <p className="bts d-flex ai-center jc-between">
                <span
                    className="btn2 b1"
                    onClick={() => setDeleteProjectId(null)}
                >
                    取消
                </span>
                <span className="btn2 b2" onClick={() => deleteProject()}>
                    確認
                </span>
            </p>
        </div>
    );
}
