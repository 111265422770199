import '../styles/popout.scss';

export default function PostPop({ setShowPostPop, showPostPop, publishProject }) {
    return (
        // 加 'act' 顯示
        <div className={`post-pop ${showPostPop ? 'act' : ''}`}>
            <div>
                <h4 className="h4">確定發佈此抽獎？</h4>
                <p className="bd1-n">
                    請注意！發佈後即代表您願意
                    <span>為該項目的抽獎結果負責</span>
                    ，並將善盡項目方之職責，提供對應獎勵給活動獲獎者。
                    <br />
                    <br />
                    如加密城市收到使用者檢舉，您未提供應賦予之獎勵，且項目有異常事件等存疑行為，經查核屬實，我們將有權利
                    <span>下架抽獎活動並永久封鎖您的帳戶</span>，請勿以身試法。
                </p>
                <div className="btns d-flex ai-center jc-between">
                    <button
                        className="btn2"
                        onClick={() => setShowPostPop(false)}
                    >
                        取消
                    </button>
                    <button className="btn2" onClick={() => publishProject()}>
                        確定發布
                    </button>
                </div>
            </div>
        </div>
    );
}
