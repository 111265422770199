/* eslint-disable no-useless-computed-key */
/* eslint-disable no-else-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router';
import DatePicker, { registerLocale } from 'react-datepicker';
// eslint-disable-next-line quotes
import 'react-datepicker/dist/react-datepicker.css';
import zh from 'date-fns/esm/locale/zh-TW';

import PostPop from '../component/PostPop';
import Success from '../component/Success';

import {
    BUTTON_KEY,
    BUTTON_MAP,
    WHITELIST_MISSION,
    MISSION_BTN_NAME,
    ACCEPT_TYPE,
} from '../common/constant';

/**
 * FB 條件: 目前只能設定是否按讚粉絲頁
 * facbook-box act 顯示按讚粉絲頁
 * facbook-box act2 顯示FB互動設定(但目前用不到)
 */

registerLocale('zh', zh);

const FbBox = ({
    clickButton,
    facebookRef,
    onClickBtn,
    addMission,
    mission,
    showFbAlert,
    hintFbCondition,
    setHintFbCondition,
}) => (
    <div
        className={`facebook-box ${showFbAlert ? 'red' : ''}  ${
            clickButton.facebook === BUTTON_KEY.FB_LIKE_PAGE ? 'act' : ''
        }`}
        ref={facebookRef}
    >
        <h2 className="h3">
            <img
                src="/images/marketBackstage/facebook_black.svg"
                alt="facebook"
            />
            是否需要與 Facebook 互動？
        </h2>
        <p className="bd4-n">
            要求抽獎者在登記項目抽獎前必須在您的 Facebook 粉絲頁按讚
        </p>
        <div>
            {[BUTTON_KEY.FB_NONE, BUTTON_KEY.FB_LIKE_PAGE].map((v) => (
                <button
                    className={`btn2 ${v === clickButton.facebook && 'act'}`}
                    key={v}
                    onClick={() => onClickBtn('facebook', v)}
                >
                    {BUTTON_MAP[v]}
                </button>
            ))}
        </div>
        <div className="more-box d-block">
            <div className="top">
                <p className="bd4-b">
                    要求抽獎者按讚粉絲頁
                    {hintFbCondition && <span>請輸入英文或數字</span>}
                </p>
                <div className="d-flex ai-center">
                    <div className="d-flex ai-center bd4-n"></div>
                    <input
                        type="text"
                        placeholder="請填寫粉絲頁token, 例如：https://www.facebook.com/CryptoCitytw/，請輸入「CryptoCitytw」"
                        onChange={(e) => {
                            if (/[A-Za-z0-9-_]+$/gm.test(e.target.value)) {
                                setHintFbCondition(false);
                            } else {
                                setHintFbCondition(true);
                            }
                            addMission(
                                WHITELIST_MISSION.LIKE_FB_PAGE,
                                'likeFbPageToken',
                                e.target.value
                            );
                        }}
                        value={
                            mission[WHITELIST_MISSION.LIKE_FB_PAGE]
                                ?.likeFbPageToken
                        }
                    />
                </div>
            </div>
        </div>
    </div>
);

/** IG 條件, 因 API 無法查驗，故暫不使用 */
const IgBox = ({
    clickButton,
    twitterRef,
    onClickBtn,
    mission,
    deleteItem,
    addMission,
}) => (
    <div
        className={`instagram-box ${
            clickButton.twitter === BUTTON_KEY.TWITTERFOLLOW
                ? 'act'
                : clickButton.twitter === BUTTON_KEY.TWITTERINTERACTION
                ? 'act2'
                : ''
        }`}
        ref={twitterRef}
    >
        <h2 className="h3">
            <img
                src="/images/marketBackstage/instagram_black.svg"
                alt="instagram_"
            />
            是否需要追蹤 Instagram & 與貼文互動？
        </h2>
        <p className="bd4-n">
            要求抽獎者在登記項目抽獎前必須成為您的 Instagram
            粉絲或與指定貼文互動
        </p>
        <div>
            {[
                BUTTON_KEY.TWITTERNONE,
                BUTTON_KEY.TWITTERFOLLOW,
                BUTTON_KEY.TWITTERINTERACTION,
            ].map((v) => (
                <button
                    className={`btn2 ${v === clickButton.twitter && 'act'}`}
                    key={v}
                    onClick={() => onClickBtn('twitter', v)}
                >
                    {BUTTON_MAP[v]}
                </button>
            ))}
        </div>
        <div className="more-box d-block">
            <div className="top">
                <p className="bd4-b">要求抽獎者追蹤指定帳號</p>
                <div className="d-flex ai-center">
                    <img
                        className="cp"
                        src="/images/marketBackstage/at.svg"
                        alt="tag"
                    />
                    <div className="d-flex ai-center">
                        {mission?.followAccount?.account?.map((v, i) => (
                            <span
                                key={`${v}-${i}`}
                                className="bd4-n"
                                onClick={() => deleteItem(i)}
                            >
                                {v.account}
                            </span>
                        ))}
                    </div>
                    <input
                        type="text"
                        placeholder="請填寫不包含@的帳號名稱 例：Crypto City"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                addMission(
                                    WHITELIST_MISSION.FOLLOWACCOUNT,
                                    'account',
                                    e.target.value.replace('@', '')
                                );
                                e.target.value = '';
                            }
                        }}
                        onBlur={(e) => {
                            if (e.target.value) {
                                addMission(
                                    WHITELIST_MISSION.FOLLOWACCOUNT,
                                    'account',
                                    e.target.value.replace('@', '')
                                );
                                e.target.value = '';
                            }
                        }}
                    />
                </div>
            </div>
            <div className="bottom">
                <div className="left" style={{ width: '100%' }}>
                    <p className="bd4-b">要求抽獎者對指定文章按愛心</p>
                    <input
                        className="bd4-n"
                        type="text"
                        placeholder="請填寫貼文網址"
                        onChange={(e) =>
                            addMission('twitterLink', 'link', e.target.value)
                        }
                        value={mission?.twitterLink?.link}
                    />
                </div>
                {/* <div className="right">
            <p className="bd4-b">
                達成指定條件
            </p>
            <div className="d-flex ai-center jc-between">

                {[
                    WHITELIST_MISSION.LIKEPOST,
                    WHITELIST_MISSION.RETWEET,
                    WHITELIST_MISSION.LIKEANDRETWITTE,
                ].map((v) => (
                    <button
                        className={`btn2 ${
                            clickButton.twitterbtn ===
                                v && 'act'
                        }`}
                        key={v}
                        onClick={() =>
                            onClickBtn(
                                'twitterbtn',
                                v
                            )
                        }
                    >
                        {MISSION_BTN_NAME[v]}
                    </button>
                ))}
            </div>
        </div> */}
            </div>
        </div>
    </div>
);

/**
 * 建立新項目
 * @returns
 */

export default function NewItem({
    showConfirmPop,
    setShowConfirmPop,
    choosePath,
    confirmText,
}) {
    const navigate = useNavigate();
    const { id } = useParams();
    const [tab, setTab] = useState(1);
    const [showPostPop, setShowPostPop] = useState(false);
    const [showSuccess, setShowSuccess] = useState(['', false]);
    const [form, setForm] = useState({
        chainType: 'ETH',
        beginTime: moment(),
        endTime: moment(),
        acceptType: 'ROLE',
    });

    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());

    const [mission, setMission] = useState({});
    const [placeholder1, setPlaceholder1] = useState(
        '請填寫不包含@的帳號名稱 例：Crypto City'
    );
    const [showInput, setShowInput] = useState(false);

    const [showDcAlert, setShowDcAlert] = useState(false);
    // const [showFbAlert, setShowFbAlert] = useState(false);
    const [showTwitterAlert, setShowTwitterAlert] = useState(false);
    const [showWalletAlert, setShowWalletAlert] = useState(false);
    const [showNftAlert, setShowNftAlert] = useState(false);
    const [showQaAlert, setShowQaAlert] = useState(false);
    const [showReadAlert, setShowReadAlert] = useState(false);

    // 抽獎名額提示
    const [hintQuota, setHintQuota] = useState(false);
    // 抽獎數量提示
    const [hintQuantity, setHintQuantity] = useState(false);
    // 鑄造價格提示
    const [hintPrice, setHintPrice] = useState(false);
    // twitter網址提示
    const [hintTwitterLink, setHintTwitterLink] = useState(false);
    // 官方網站提示
    const [hintWeb, setHintWeb] = useState(false);
    // dc網址提示
    const [hintDcLink, setHintDcLink] = useState(false);
    // 鑄造日期提示
    const [hasTime, setHasTime] = useState(false);

    const [hintTwitterAccount, setHintTwitterAccount] = useState(false);
    // twitter條件提示
    const [hintTwitterCondition, setHintTwitterCondition] = useState(false);
    // fb條件提示
    // const [hintFbCondition, setHintFbCondition] = useState(false);
    // 錢包金額提示
    const [hintWalletCondition, setHintWalletCondition] = useState(false);
    // opensea網址提示
    const [hintOpenseaCondition, setHintOpenseaCondition] = useState(false);

    const [hintServerId, setHintServerId] = useState(false);
    const [hintServerLink, setHintServerLink] = useState(false);
    const [hintRoleId, setHintRoleId] = useState(false);

    // role特殊加成數量
    const [raffle, setRaffle] = useState([1]);
    // 閱讀文章
    const [article, setArticle] = useState('');
    const [showArticle, setShowArticle] = useState(false);

    const [clickButton, setClickButton] = useState({
        discord: BUTTON_KEY.DISCORDNONE,
        NFT: BUTTON_KEY.NFTNONE,
        QA: BUTTON_KEY.QANONE,
        twitter: BUTTON_KEY.TWITTERNONE,
        wallet: BUTTON_KEY.WALLETNONE,
        twitterbtn: WHITELIST_MISSION.LIKEANDRETWITTE,
        // facebook: BUTTON_KEY.FB_NONE,
    });
    const [draftMission, setDraftMission] = useState({});
    const discordRef = useRef(null);
    const twitterRef = useRef(null);

    // const facebookRef = useRef(null);
    const walletRef = useRef(null);
    const NFTRef = useRef(null);
    const QARef = useRef(null);
    const readRef = useRef(null);
    const mainRef = useRef(null);

    const accountRef = useRef(null);

    const onClickNext = () => setTab(tab + 1);

    const onChange = (key, value) => {
        setForm({ ...form, [key]: value });
    };

    // 當建立新項目程序進行到 tab 3 的時候，利用 form.twitterLink 的值取得 Twitter 的 banner 與 image。
    const getTwitterPictures = async (twitterLink) => {
        const data = await getTwitterImgAndBanner(getTwitterName(twitterLink));

        return data;
    };
    // 在 getTwitterPictures 內部調用此函式。從 form.twitterLink 擷取 Twitter 使用者名稱
    const getTwitterName = (url) => {
        const urlArray = url.split('/');

        return urlArray.pop();
    };
    // 在 getTwitterPictures 內部調用此函式。利用 Twitter 使用者名稱取得 Twitter 的 banner 與 image。
    const getTwitterImgAndBanner = async (name) => {
        const { data } = await axios.get(
            `/api/whitelist/get-twitter-img-and-banner?twitterName=${name}`
        );

        return {
            banner: data.data.banner,
            image: data.data.image,
        };
    };

    const onClickBtn = (category, value) => {
        setClickButton({ ...clickButton, [category]: value });
    };

    const handleOnPreview = async (event, name) => {
        const formData = new FormData();
        const file = event.target.files[0];
        formData.append('image', file);
        const { data } = await axios.post(
            '/api/whitlist/upload-image',
            formData
        );
        onChange(name, data);
    };

    const createMarkup = () => ({
        __html: form.content.replaceAll('\n', '<br/>'),
    });

    const addMission = (missionName, key, value) => {
        if (missionName === 'followAccount') {
            const newMission = { ...mission };
            const insertData = { [key]: value };
            if (newMission[missionName] && newMission[missionName][key]) {
                newMission[missionName][key].push(insertData);
            } else {
                newMission[missionName] = { [key]: [insertData] };
            }
            setMission(newMission);

            return;
        }
        setMission({
            ...mission,
            [missionName]: { ...mission[missionName], [key]: value },
        });
    };

    const submitDraft = async () => {
        // try {
        //     const { data } = await axios.post('/api/whitlist/save-draft', form);
        //     if (data) {
        // setWhitelistId(data);
        setTab(tab + 1);
        //     }
        // } catch (err) {
        //     console.error(err);
        // }
    };

    const deleteItem = (index) => {
        const newMission = { ...mission };
        newMission.followAccount.account =
            newMission.followAccount.account.filter((v, i) => i !== index);
        setMission(newMission);
    };

    const checkMission = () => {
        setShowDcAlert(false);
        // setShowFbAlert(false);
        setShowTwitterAlert(false);
        setShowWalletAlert(false);
        setShowNftAlert(false);
        setShowQaAlert(false);

        const { discord, NFT, QA, twitter, twitterbtn, wallet } = clickButton;
        const {
            gettingQualify,
            followAccount,
            twitterLink,
            checkNFT,
            checkWallet,
            // likeFbPage,
        } = mission;
        const newMission = {};

        // 檢查discord條件
        if (discord !== BUTTON_KEY.DISCORDNONE) {
            const { DcLink, qualify, serverName, serverId, qualifyID } =
                gettingQualify || {};
            const newData = { DcLink, serverId, serverName };
            if (
                !gettingQualify ||
                !DcLink ||
                !serverId ||
                !serverName ||
                hintServerLink ||
                hintServerId
            ) {
                setShowDcAlert(true);

                return discordRef.current.scrollIntoView({ block: 'center' });
                // eslint-disable-next-line no-else-return
            }
            if (discord === BUTTON_KEY.DSICORDROLE) {
                if (!qualify || !qualifyID || hintRoleId) {
                    setShowDcAlert(true);

                    return discordRef.current.scrollIntoView({
                        block: 'center',
                    });
                    // eslint-disable-next-line no-else-return
                }
                newData.qualify = qualify;
                newData.qualifyID = qualifyID;
            }
            newMission.gettingQualify = newData;
        }
        // 檢查twitter條件
        if (twitter !== BUTTON_KEY.TWITTERNONE) {
            // 若有指定 twitter一定有 flollowAccount
            const { account } = followAccount || {};
            if (!account) {
                setShowTwitterAlert(true);

                return twitterRef.current.scrollIntoView({ block: 'center' });
            }
            newMission.followAccount = followAccount;

            if (twitter === BUTTON_KEY.TWITTERINTERACTION) {
                if (!twitterbtn || !twitterLink || hintTwitterCondition) {
                    setShowTwitterAlert(true);

                    return twitterRef.current.scrollIntoView({
                        block: 'center',
                    });
                }
                let missionKey;
                switch (twitterbtn) {
                    case WHITELIST_MISSION.LIKEPOST:
                        missionKey = 'like';
                        break;
                    case WHITELIST_MISSION.RETWEET:
                        missionKey = 'retweet';
                        break;
                    case WHITELIST_MISSION.LIKEANDRETWITTE:
                        missionKey = 'share';
                        break;
                    default:
                        break;
                }

                // 必須使用 array
                newMission[twitterbtn] = {
                    [missionKey]: [{ [missionKey]: twitterLink.link }],
                };
            }
        }
        // 檢查 FB 條件
        // if (facebook !== BUTTON_KEY.FB_NONE) {

        //     if (!mission?.likeFbPage || hintFbCondition) {
        //         setShowFbAlert(true);

        //         return facebookRef.current.scrollIntoView({ block: 'center' });
        //     }
        //     newMission.likeFbPage = {
        //         likeFbPageToken: mission.likeFbPage.likeFbPageToken,
        //     };
        // }
        // // 檢查錢包條件
        if (wallet !== BUTTON_KEY.WALLETNONE) {
            if (!checkWallet?.min || hintWalletCondition) {
                setShowWalletAlert(true);

                return walletRef.current.scrollIntoView({ block: 'center' });
            }
            const newData = {
                ...checkWallet,
                coinType: 'ETH',
                walletType: 'ETH',
            };
            newMission.checkWallet = newData;
        }
        // 檢查NFT條件
        if (NFT !== BUTTON_KEY.NFTNONE) {
            if (!checkNFT?.NFTName) {
                setShowNftAlert(true);

                return NFTRef.current.scrollIntoView({ block: 'center' });
            }
            const newCheckNFT = checkNFT?.NFT.split('/')[4];
            if (!newCheckNFT || hintOpenseaCondition) {
                setShowNftAlert(true);

                return NFTRef.current.scrollIntoView({ block: 'center' });
            }
            const newData = {
                ...checkNFT,
                NFT: newCheckNFT,
                NFTWalletType: 'ETH',
            };
            newMission.checkNFT = newData;
        }
        // 檢查QA條件
        if (QA !== BUTTON_KEY.QANONE) {
            if (!mission?.QA?.question) {
                setShowQaAlert(true);

                return QARef.current.scrollIntoView({ block: 'center' });
            }
            newMission.QA = { question: mission.QA.question };
        }

        setTab(tab + 1);
        setDraftMission(newMission);

        return newMission;
    };

    const saveDraft = async () => {
        const { data } = await axios.post('/api/whitlist/save-draft', {
            mission: draftMission,
            form: { ...form, displayTime: form.beginTime },
        });
        setShowSuccess(['成功儲存草稿', true]);
        setTimeout(() => navigate(`/confirm/${data}`), 2500);
    };

    const publishProject = async () => {
        const { data } = await axios.post('/api/whitlist/save-draft', {
            mission: draftMission,
            form: { ...form, status: 'on', displayTime: form.beginTime },
        });
        setShowSuccess(['已成功發佈', true]);
        setTimeout(() => navigate(`/confirm/${data}`), 2500);
    };

    function isNumeric(value) {
        return /^\+?[1-9]\d*$/.test(value);
    }

    const getProject = async () => {
        const { data } = await axios.get('/api/whitelist/get-own-project', {
            params: { id },
        });
        setForm({ ...data, image: data.image[0].image });
        const {
            qualifyID,
            serverId,
            account,
            share,
            like,
            retweet,
            min,
            NFTName,
            question,
            DcLink,
            qualify,
            serverName,
            // likeFbPageToken,
        } = data;
        let discord;
        let NFT;
        let QA;
        let twitter;
        let twitterbtn;
        let wallet;
        // let facebook;
        const missionOutput = {
            gettingQualify: {
                DcLink,
                qualify,
                serverName,
                qualifyID,
                serverId,
            },
            followAccount: {
                account,
            },
            checkWallet: {
                min,
            },
            checkNFT: {
                NFTName,
                NFT: `https://opensea.io/collection/${data.NFT}`,
            },
            QA: {
                question,
            },
            // likeFbPage: {
            //     likeFbPageToken,
            // },
        };
        if (qualifyID) {
            discord = BUTTON_KEY.DSICORDROLE;
        } else if (serverId) {
            discord = BUTTON_KEY.DISCORDMEMBER;
        } else {
            discord = BUTTON_KEY.DISCORDNONE;
        }
        if (share || like || retweet) {
            twitter = BUTTON_KEY.TWITTERINTERACTION;
            if (share) {
                twitterbtn = WHITELIST_MISSION.LIKEANDRETWITTE;
                missionOutput.twitterLink = {
                    link: share.share,
                };
            } else if (like) {
                twitterbtn = WHITELIST_MISSION.LIKEPOST;
                missionOutput.twitterLink = {
                    link: like.like,
                };
            } else if (retweet) {
                twitterbtn = WHITELIST_MISSION.RETWEET;
                missionOutput.twitterLink = {
                    link: retweet.retweet,
                };
            }
        } else if (account) {
            twitter = BUTTON_KEY.TWITTERFOLLOW;
        } else {
            twitter = BUTTON_KEY.TWITTERNONE;
        }
        if (min) {
            wallet = BUTTON_KEY.WALLETPRICE;
        } else {
            wallet = BUTTON_KEY.WALLETNONE;
        }
        if (NFTName) {
            NFT = BUTTON_KEY.NFTREQUIRE;
        } else {
            NFT = BUTTON_KEY.NFTNONE;
        }
        if (question) {
            QA = BUTTON_KEY.QA;
        } else {
            QA = BUTTON_KEY.QANONE;
        }
        // if (likeFbPageToken) {
        //     facebook = BUTTON_KEY.FB_LIKE_PAGE;
        // } else {
        //     facebook = BUTTON_KEY.FB_NONE;
        // }
        setClickButton({
            discord,
            NFT,
            QA,
            twitter,
            twitterbtn,
            wallet,
            // facebook,
        });
        setMission(missionOutput);
    };

    useEffect(() => {
        if (id) {
            getProject();
        }
    }, [id]);

    useEffect(() => {
        mainRef.current.scrollTo({ top: 0 });
    }, [tab]);

    // 當建立新項目程序進行到 tab 3 的時候，利用 form.twitterLink 的值取得 Twitter 的 banner 與 image。
    useEffect(() => {
        if (tab === 3) {
            (async () => {
                const result = await getTwitterPictures(form.twitterLink);
                if (result) {
                    setForm({
                        ...form,
                        ['image']:
                            result.image ||
                            '/images/marketBackstage/default_main.svg',
                        ['banner']:
                            result.banner ||
                            '/images/marketBackstage/default_sub.svg',
                    });
                }
            })();
        }
    }, [tab]);

    return (
        <>
            <div className="main-content" ref={mainRef}>
                {/* 防呆流程 */}
                <section id="procedure" className={`d-flex ai-center p${tab}p`}>
                    <div className="p1">
                        <p className="bd4-n">1.抽獎時間&名額</p>
                    </div>
                    <div className="p2">
                        <p className="bd4-n">2.項目方基本資訊</p>
                    </div>
                    <div className="p3">
                        <p className="bd4-n">3.項目介紹資訊</p>
                    </div>
                    <div className="p4">
                        <p className="bd4-n">4.指定抽獎條件</p>
                    </div>
                    <div className="p5">
                        <p className="bd4-n">5.草稿或發佈</p>
                    </div>
                </section>

                {/* 1.抽獎時間填寫 */}
                {tab === 1 && (
                    <div className="lottery-time d-flex flex-column ai-center jc-start">
                        <h1 className="h1">希望從何時開始抽獎？</h1>
                        <div className="inner">
                            <h6 className="bd4-b">抽獎開始時間</h6>
                            <DatePicker
                                timeFormat="HH:mm"
                                showTimeSelect
                                locale="zh"
                                timeIntervals={10}
                                selected={start}
                                minDate={new Date()}
                                onChange={(date) => {
                                    setStart(date);
                                    onChange('beginTime', date);
                                }}
                                dateFormat="yyyy/MM/dd  HH:mm"
                            />

                            <h6 className="bd4-b">抽獎結束時間</h6>
                            <DatePicker
                                timeFormat="HH:mm"
                                showTimeSelect
                                timeIntervals={10}
                                selected={end}
                                locale="zh"
                                onChange={(date) => {
                                    setEnd(date);
                                    onChange('endTime', date);
                                }}
                                minDate={start}
                                dateFormat="yyyy/MM/dd  HH:mm"
                            />

                            <h5 className="bd4-n">
                                系統將會在您設定的結束時間自動抽獎
                            </h5>
                            <h6 className={`bd4-b ${hintQuota ? 'hint' : ''}`}>
                                抽獎名額
                            </h6>
                            <input
                                className="bd4-n"
                                type="text"
                                placeholder="100"
                                onChange={(e) => {
                                    onChange('quota', e.target.value);
                                    if (/^\+?[1-9]\d*$/.test(e.target.value)) {
                                        setHintQuota(false);
                                    } else {
                                        setHintQuota(true);
                                    }
                                }}
                                value={form.quota}
                            />
                            <button
                                className={`btn2 ${
                                    (!form.beginTime ||
                                        !form.endTime ||
                                        !isNumeric(form.quota)) &&
                                    'act'
                                }`}
                                disabled={
                                    !form.beginTime ||
                                    !form.endTime ||
                                    !isNumeric(form.quota)
                                }
                                onClick={() => onClickNext()}
                            >
                                下一步
                            </button>
                        </div>
                    </div>
                )}

                {/* 2.項目資訊填寫 */}
                {tab === 2 && (
                    <div className="base-info d-flex flex-column ai-center jc-start">
                        <h1 className="h1">項目基本資訊</h1>
                        <div className="inner">
                            <h6 className="bd4-b">鑄造日期</h6>
                            <input
                                className={`bd4-n ${
                                    hasTime ? 'act' : ''
                                } date-input`}
                                type="date"
                                placeholder="如果尚未確定，可以留白，系統將顯示TBD"
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setHasTime(false);
                                    } else {
                                        setHasTime(true);
                                    }
                                    onChange('mintTime', e.target.value);
                                }}
                                onFocus={(e) => {
                                    if (e.target.value === '') {
                                        setHasTime(false);
                                    } else {
                                        setHasTime(true);
                                    }
                                }}
                                value={
                                    // form.mintTime &&
                                    // moment(form.mintTime).format('YYYY-MM-DD')
                                    form.mintTime
                                }
                            />

                            <h6
                                className={`bd4-b ${
                                    hintQuantity ? 'hint' : ''
                                }`}
                            >
                                發行數量
                            </h6>
                            <input
                                className="bd4-n"
                                type="text"
                                placeholder="如果尚未確定，可以留白，系統將顯示TBD"
                                onChange={(e) => {
                                    if (
                                        /^\+?[1-9]\d*$/.test(e.target.value) ||
                                        e.target.value.trim() === ''
                                    ) {
                                        setHintQuantity(false);
                                    } else {
                                        setHintQuantity(true);
                                    }
                                    onChange('issue', e.target.value);
                                }}
                                value={form.issue}
                            />

                            <h6 className="bd4-b">鑄造價格</h6>
                            <div className="price-box">
                                <button
                                    className={`btn2 ${
                                        form.chainType === 'ETH' ? 'act' : ''
                                    }`}
                                    onClick={() => onChange('chainType', 'ETH')}
                                >
                                    ETH
                                </button>
                                <button
                                    className={`btn2 ${
                                        form.chainType === 'SOL' ? 'act' : ''
                                    }`}
                                    onClick={() => onChange('chainType', 'SOL')}
                                >
                                    Solana
                                </button>
                                {hintPrice && (
                                    <span
                                        className={`bd4-b ${
                                            hintPrice ? 'hint' : ''
                                        }`}
                                    >
                                        請輸入正確數字
                                    </span>
                                )}
                            </div>
                            <input
                                className="bd4-n"
                                type="text"
                                placeholder="輸入-1為TBD，輸入0或空值，則為Free"
                                onChange={(e) => {
                                    if (
                                        /^\+?[0-9]\d*$/.test(e.target.value) ||
                                        e.target.value.trim() === '' ||
                                        e.target.value === '-1'
                                    ) {
                                        setHintPrice(false);
                                    } else {
                                        setHintPrice(true);
                                    }
                                    onChange('mintPrice', e.target.value);
                                }}
                                value={form.mintPrice}
                            />

                            <h6
                                className={`bd4-b ${
                                    hintTwitterLink ? 'hintlink' : ''
                                }`}
                            >
                                Twitter
                            </h6>
                            <input
                                className="bd4-n"
                                type="text"
                                placeholder="請輸入網址"
                                onChange={(e) => {
                                    if (
                                        /(https?:\/\/)?twitter\.com\/[A-Za-z0-9-_\/]+$/gm.test(
                                            e.target.value
                                        )
                                    ) {
                                        setHintTwitterLink(false);
                                    } else {
                                        setHintTwitterLink(true);
                                    }
                                    onChange('twitterLink', e.target.value);
                                }}
                                value={form.twitterLink}
                            />

                            <h6 className={`bd4-b ${hintWeb ? 'hintweb' : ''}`}>
                                官方網站
                                {hintWeb && (
                                    <span className="bd4-b">
                                        請輸入正確網址或留白
                                    </span>
                                )}
                            </h6>
                            <input
                                className="bd4-n"
                                type="text"
                                placeholder="請輸入網址"
                                onChange={(e) => {
                                    if (
                                        e.target.value === '' ||
                                        /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/gm.test(
                                            e.target.value
                                        )
                                    ) {
                                        setHintWeb(false);
                                    } else {
                                        setHintWeb(true);
                                    }
                                    onChange('officalLink', e.target.value);
                                }}
                                value={form.officalLink}
                            />

                            <h6
                                className={`bd4-b ${
                                    hintDcLink ? 'hintweb' : ''
                                }`}
                            >
                                Discord{' '}
                                {hintDcLink && (
                                    <span className="bd4-b">
                                        請輸入正確網址或留白
                                    </span>
                                )}
                            </h6>
                            <input
                                className="bd4-n"
                                type="text"
                                placeholder="請輸入網址"
                                onChange={(e) => {
                                    if (
                                        e.target.value === '' ||
                                        /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/gm.test(
                                            e.target.value
                                        )
                                    ) {
                                        setHintDcLink(false);
                                    } else {
                                        setHintDcLink(true);
                                    }
                                    onChange('discordLink', e.target.value);
                                }}
                                value={form.discordLink}
                            />
                            <button
                                className={`btn2 ${
                                    (!form.twitterLink ||
                                        hintQuantity ||
                                        hintPrice ||
                                        hintTwitterLink ||
                                        hintWeb ||
                                        hintDcLink) &&
                                    'act'
                                }`}
                                disabled={
                                    !form.twitterLink ||
                                    hintQuantity ||
                                    hintPrice ||
                                    hintTwitterLink ||
                                    hintWeb ||
                                    hintDcLink
                                }
                                onClick={() => onClickNext()}
                            >
                                下一步
                            </button>
                        </div>
                    </div>
                )}

                {/* 3.項目基本資料 */}
                {tab === 3 && (
                    <div className="item-info">
                        <h1 className="h1">項目介紹資訊</h1>

                        <div className="inner">
                            {/* 資訊欄 */}
                            <div className="info-box d-flex ai-center">
                                {/* 項目頭像 */}
                                <div className="img-box">
                                    <img
                                        src={
                                            form?.image
                                                ? form?.image
                                                : '/images/marketBackstage/default_main.svg'
                                        }
                                        alt="thumbnail"
                                    />
                                </div>

                                <div className="content">
                                    <p className="bd1-n">
                                        發行數量：
                                        {form.issue ? form.issue : 'TBD'}
                                    </p>
                                    <p className="bd1-n">
                                        抽獎名額：{form.quota}
                                    </p>
                                </div>

                                <div className="content">
                                    <p className="bd1-n">
                                        鑄造時間：
                                        {form.mintTime
                                            ? moment(form.mintTime).format(
                                                  'YYYY.MM.DD'
                                              )
                                            : 'TBD'}
                                    </p>
                                    <p className="bd1-n d-flex ai-center">
                                        鑄造價格：
                                        <img
                                            src={`/images/marketBackstage/${form.chainType}.svg`}
                                            alt="currency"
                                        />
                                        {form.mintPrice < 0
                                            ? 'TBD'
                                            : form.mintPrice
                                            ? form.mintPrice
                                            : 'Free'}
                                    </p>
                                </div>

                                <div className="content">
                                    <p className="bd1-n">
                                        開獎時間：
                                        {moment(form.endTime).format(
                                            'YYYY.MM.DD'
                                        )}
                                    </p>
                                    <p className="bd1-n d-flex ai-center">
                                        連結：
                                        {form.officalLink && (
                                            <a
                                                href={form.officalLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/web.svg"
                                                    alt="website"
                                                />
                                            </a>
                                        )}
                                        {form.twitterLink && (
                                            <a
                                                href={form.twitterLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/tw.svg"
                                                    alt="twitter"
                                                />
                                            </a>
                                        )}
                                        {form.discordLink && (
                                            <a
                                                href={form.discordLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/dc.svg"
                                                    alt="discord"
                                                />
                                            </a>
                                        )}
                                    </p>
                                </div>
                            </div>

                            <h6 className="bd4-b">抽獎名稱</h6>
                            <input
                                className="bd4-n"
                                type="text"
                                placeholder="請填寫本次計劃抽獎的名稱標題"
                                onChange={(e) =>
                                    onChange('title', e.target.value)
                                }
                                value={form.title}
                            />
                            <h6 className="bd4-b">兌換獎項</h6>
                            <input
                                type="text"
                                className="bd4-n"
                                placeholder="請填寫單一獎項名稱，例：The Hootis 白名單"
                                onChange={(e) =>
                                    onChange('award', e.target.value)
                                }
                                value={form.award || ''}
                            />

                            {/* 12/16新增 領獎方式 */}
                            <h6 className="bd4-b">兑獎方式</h6>
                            <div className="accept-box d-flex ai-center jc-between">
                                {Object.keys(ACCEPT_TYPE).map((v, i) => (
                                    <button
                                        className={
                                            v === form.acceptType ? 'act' : ''
                                        }
                                        key={i}
                                        onClick={(e) =>
                                            onChange('acceptType', v)
                                        }
                                    >
                                        {ACCEPT_TYPE[v]}
                                    </button>
                                ))}
                            </div>

                            {/* 上傳主視覺 */}
                            <h6 className="bd4-b">主視覺</h6>
                            <div className="upload-main d-flex ai-center jc-start">
                                {/* 預設圖 default_main.svg */}
                                <img
                                    src={
                                        form?.image
                                            ? form?.image
                                            : '/images/marketBackstage/default_main.svg'
                                    }
                                    alt="main"
                                />
                                <div className="right">
                                    <label
                                        htmlFor="upFile"
                                        className="bd4-n cp"
                                    >
                                        上傳主視覺
                                        <input
                                            id="upFile"
                                            type="file"
                                            className="cp"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={(e) =>
                                                handleOnPreview(e, 'image')
                                            }
                                        />
                                    </label>
                                    <p className="bd4-n">
                                        請上傳 Jpg、JPEG、PNG、GIF、MP4 等格式
                                        <br />
                                        <br />
                                        檔案大小限制 1mb
                                    </p>
                                </div>
                            </div>

                            <h6 className="bd4-b">橫幅圖像</h6>
                            <div className="upload-sub">
                                {/* 預設圖 default_sub.svg */}
                                <img
                                    src={
                                        form?.banner
                                            ? form.banner
                                            : '/images/marketBackstage/default_sub.svg'
                                    }
                                    alt="sub"
                                />
                                <div className="right">
                                    <label
                                        className="bd4-n cp"
                                        htmlFor="upFile2"
                                    >
                                        上傳橫幅圖像
                                        <input
                                            id="upFile2"
                                            className="cp"
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={(e) =>
                                                handleOnPreview(e, 'banner')
                                            }
                                        />
                                    </label>
                                    <p className="bd4-n">
                                        請上傳 Jpg、JPEG、PNG、GIF、MP4
                                        等格式檔案大小限制 1mb
                                    </p>
                                </div>
                            </div>

                            <h6 className="bd4-b">簡介</h6>
                            <p className="bd4-n">
                                請向玩家介紹您的項目，包含但不限於階段性目標、項目附能、項目理念、想要完成的事情、以及未來的發展等。
                            </p>
                            <textarea
                                className="bd4-n"
                                onChange={(e) =>
                                    onChange('content', e.target.value)
                                }
                                value={form.content}
                            />
                            <p className="bts">
                                <button
                                    className="btn2"
                                    onClick={() => setTab(2)}
                                >
                                    返回修改
                                </button>
                                <button
                                    className={`btn2 ${
                                        (!form.title ||
                                            !form.award ||
                                            !form.image ||
                                            !form.banner ||
                                            !form.content) &&
                                        'act'
                                    }`}
                                    disabled={
                                        !form.title ||
                                        !form.award ||
                                        !form.image ||
                                        !form.banner ||
                                        !form.content
                                    }
                                    onClick={() => onClickNext()}
                                >
                                    下一步
                                </button>
                            </p>
                        </div>
                    </div>
                )}

                {/* 4.確認詳情 */}
                {tab === 4 && (
                    <div className="confirm-wrapper">
                        <h1 className="h1">確認詳情</h1>
                        <div className="inner">
                            <div className="info-block">
                                {/* 項目視覺資訊 */}
                                <div className="main-info">
                                    {/* 背景圖 */}
                                    <div className="bg-box">
                                        <img
                                            src={
                                                form?.image
                                                    ? form?.banner
                                                    : '/images/marketBackstage/default_main.svg'
                                            }
                                            alt="background"
                                        />
                                    </div>
                                    {/* 主視覺 */}
                                    <div className="vision-box d-flex jc-start ai-center">
                                        <div className="img-box">
                                            <img
                                                src={
                                                    form?.image
                                                        ? form?.image
                                                        : '/images/marketBackstage/default_main.svg'
                                                }
                                                alt="item"
                                            />
                                        </div>
                                    </div>

                                    {/* 發行資訊 */}
                                    <div className="item-text ai-center d-flex flex-wrap jc-between">
                                        <p className="bd1-n first">
                                            發行數量：
                                            {form.issue ? form.issue : 'TBD'}
                                        </p>
                                        <p className="bd1-n first">
                                            鑄造時間：
                                            {form.mintTime
                                                ? moment(form.mintTime).format(
                                                      'YYYY.MM.DD'
                                                  )
                                                : 'TBD'}
                                        </p>
                                        <p className="bd1-n first">
                                            開獎時間：
                                            {moment(form.endTime).format(
                                                'YYYY.MM.DD'
                                            )}
                                        </p>
                                        <p className="bd1-n">
                                            抽獎名額：{form.quota}
                                        </p>
                                        <p className="bd1-n">
                                            鑄造價格：
                                            <img
                                                style={{ marginRight: '4px' }}
                                                src="/images/marketBackstage/ETH.svg"
                                                alt="ETH"
                                            />
                                            {form.mintPrice < 0
                                                ? 'TBD'
                                                : form.mintPrice
                                                ? form.mintPrice
                                                : 'Free'}
                                        </p>
                                        <p className="bd1-n link-box">
                                            連結：
                                            {form.officalLink && (
                                                <a
                                                    href={form.officalLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src="/images/marketBackstage/web.svg"
                                                        alt="website"
                                                    />
                                                </a>
                                            )}
                                            {form.twitterLink && (
                                                <a
                                                    href={form.twitterLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src="/images/marketBackstage/tw.svg"
                                                        alt="twitter"
                                                    />
                                                </a>
                                            )}
                                            {form.discordLink && (
                                                <a
                                                    href={form.discordLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src="/images/marketBackstage/dc.svg"
                                                        alt="discord"
                                                    />
                                                </a>
                                            )}
                                        </p>
                                    </div>
                                </div>

                                {/* 兌換獎項 */}
                                <div className="exchange-box d-flex ai-center jc-between">
                                    <div className="left d-flex ai-center">
                                        <div className="img-box">
                                            <img
                                                src="/images/marketBackstage/present.svg"
                                                alt="prize"
                                            />
                                        </div>
                                        <h4 className="h6">
                                            兌換獎項：{form.award}
                                        </h4>
                                    </div>
                                    <p className="bd4-n">
                                        目前已獲得抽獎券數：0
                                    </p>
                                </div>

                                {/* 領獎方式 */}
                                <div className="accept-box d-flex ai-center jc-start">
                                    <h6 className="h6">領獎方式</h6>
                                    <p className="bd4-b">
                                        {ACCEPT_TYPE[form.acceptType]}
                                    </p>
                                </div>

                                {/* 資訊 */}
                                <p
                                    className="info-content bd1-n"
                                    dangerouslySetInnerHTML={createMarkup()}
                                ></p>
                                <div className="btn-box d-flex jc-between">
                                    <button
                                        className="btn2 back"
                                        onClick={() => setTab(3)}
                                    >
                                        返回修改
                                    </button>
                                    <button
                                        className="btn2 save"
                                        onClick={() => submitDraft()}
                                    >
                                        設定抽獎條件
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* 5.設定登記抽獎條件 */}
                {tab === 5 && (
                    <div className="set-box">
                        <h1 className="h1">設定登記抽獎條件</h1>

                        <div className="inner">
                            {/* discord */}
                            {/* 加 act 顯示 加入會員
                                加 act2 顯示 擁有身份
                            */}
                            <div
                                className={`dc-box ${
                                    showDcAlert ? 'red' : ''
                                } ${
                                    clickButton.discord ===
                                    BUTTON_KEY.DISCORDMEMBER
                                        ? 'act'
                                        : clickButton.discord ===
                                          BUTTON_KEY.DSICORDROLE
                                        ? 'act2'
                                        : ''
                                }`}
                                ref={discordRef}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/discord_black.svg"
                                        alt="discord"
                                    />
                                    是否需要加入指定 Discord & 取得指定身份？
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎前必須成為您的
                                    Discord 會員或擁有指定身份
                                </p>
                                <div>
                                    {[
                                        BUTTON_KEY.DISCORDNONE,
                                        BUTTON_KEY.DISCORDMEMBER,
                                        BUTTON_KEY.DSICORDROLE,
                                    ].map((v) => (
                                        <button
                                            key={v}
                                            className={`btn2 ${
                                                v === clickButton.discord &&
                                                'act'
                                            }`}
                                            onClick={() =>
                                                onClickBtn('discord', v)
                                            }
                                        >
                                            {BUTTON_MAP[v]}
                                        </button>
                                    ))}
                                </div>

                                <div className="more-box">
                                    <div className="top">
                                        <h5 className="h5">
                                            指定 加入的 Discord
                                        </h5>
                                        <p className="bd4-b">
                                            Server ID
                                            <a
                                                href="/explanation"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/mark.svg"
                                                    alt="more"
                                                />
                                            </a>
                                            {hintServerId && (
                                                <span>請輸入正確數字</span>
                                            )}
                                        </p>
                                        <input
                                            type="text"
                                            placeholder="987325346758"
                                            onChange={(e) => {
                                                if (
                                                    /^[1-9]\d*$/gm.test(
                                                        e.target.value
                                                    )
                                                ) {
                                                    setHintServerId(false);
                                                } else {
                                                    setHintServerId(true);
                                                }
                                                addMission(
                                                    WHITELIST_MISSION.GETTINGQUALIFY,
                                                    'serverId',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                mission?.gettingQualify
                                                    ?.serverId
                                            }
                                        />
                                        <p className="bd4-b">Server 顯示名稱</p>
                                        <input
                                            type="text"
                                            placeholder="加密城市實驗室"
                                            onChange={(e) =>
                                                addMission(
                                                    WHITELIST_MISSION.GETTINGQUALIFY,
                                                    'serverName',
                                                    e.target.value
                                                )
                                            }
                                            value={
                                                mission?.gettingQualify
                                                    ?.serverName
                                            }
                                        />
                                        <p className="bd4-b">
                                            Server 連接
                                            {hintServerLink && (
                                                <span>請輸入正確網址</span>
                                            )}
                                        </p>
                                        <input
                                            type="text"
                                            placeholder="https://www.cryptocity.tw/"
                                            onChange={(e) => {
                                                if (
                                                    /^[A-Za-z0-9\/\.\:\_\-]+$/gm.test(
                                                        e.target.value
                                                    )
                                                ) {
                                                    setHintServerLink(false);
                                                } else {
                                                    setHintServerLink(true);
                                                }
                                                addMission(
                                                    WHITELIST_MISSION.GETTINGQUALIFY,
                                                    'DcLink',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                mission?.gettingQualify?.DcLink
                                            }
                                        />
                                    </div>

                                    <div className="bottom">
                                        <h5 className="h5">
                                            擁有指定 Discord 身份
                                        </h5>
                                        <p className="bd4-b">
                                            Role ID
                                            <a
                                                href="/explanation"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/mark.svg"
                                                    alt="more"
                                                />
                                            </a>
                                            {hintRoleId && (
                                                <span>請輸入正確數字</span>
                                            )}
                                        </p>
                                        <input
                                            type="text"
                                            placeholder="987325346758"
                                            onChange={(e) => {
                                                if (
                                                    /^[1-9]\d*$/gm.test(
                                                        e.target.value
                                                    )
                                                ) {
                                                    setHintRoleId(false);
                                                } else {
                                                    setHintRoleId(true);
                                                }
                                                addMission(
                                                    WHITELIST_MISSION.GETTINGQUALIFY,
                                                    'qualifyID',
                                                    e.target.value
                                                );
                                            }}
                                            value={
                                                mission?.gettingQualify
                                                    ?.qualifyID
                                            }
                                        />
                                        <p className="bd4-b">Role 顯示標籤</p>
                                        <input
                                            type="text"
                                            placeholder="加密城市優秀市民"
                                            onChange={(e) =>
                                                addMission(
                                                    WHITELIST_MISSION.GETTINGQUALIFY,
                                                    'qualify',
                                                    e.target.value
                                                )
                                            }
                                            value={
                                                mission?.gettingQualify?.qualify
                                            }
                                        />
                                    </div>

                                    {/* 抽獎券加成 */}
                                    {/* <div className="raffle">
                                        <h5 className="h5">
                                            擁有指定 Discord 身份特殊加成
                                        </h5>
                                        <h6 className="bd4-n">
                                            可設定擁有指定 Discord Role
                                            可獲得額外加成的抽獎券
                                        </h6>
                                        {raffle.map((r, i) => (
                                            <div
                                                className="raffle-box"
                                                key={`raffle-${i}`}
                                            >
                                                <div className="first d-flex ai-center jc-between">
                                                    <div>
                                                        <p className="bd4-b">
                                                            Role ID
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder="987325346758"
                                                            value={
                                                                mission
                                                                    ?.gettingQualify
                                                                    ?.qualifyID
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="bd4-b">
                                                            Role 顯示標籤
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder="加密城市優秀市民"
                                                            value={
                                                                mission
                                                                    ?.gettingQualify
                                                                    ?.qualify
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="bd4-b">
                                                        加成抽獎券數
                                                    </p>
                                                    <input
                                                        type="text"
                                                        placeholder="請填寫純數字單位"
                                                        value={
                                                            mission
                                                                ?.gettingQualify
                                                                ?.qualify
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                        <button
                                            className="btn2"
                                            onClick={() =>
                                                setRaffle(
                                                    (prev) => prev.concat([1])
                                                    // eslint-disable-next-line function-paren-newline
                                                )
                                            }
                                        >
                                            新增加成Role
                                        </button>
                                    </div> */}
                                </div>
                            </div>

                            {/* twitter */}
                            {/* 加 act 顯示 需要追蹤
                                加 act2 顯示 追蹤並互動
                            */}
                            <div
                                className={`twitter-box
                                ${showTwitterAlert ? 'red' : ''}
                                ${
                                    clickButton.twitter ===
                                    BUTTON_KEY.TWITTERFOLLOW
                                        ? 'act'
                                        : clickButton.twitter ===
                                          BUTTON_KEY.TWITTERINTERACTION
                                        ? 'act2'
                                        : ''
                                }`}
                                ref={twitterRef}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/twitter_black.svg"
                                        alt="twitter"
                                    />
                                    是否需要追蹤 Twitter & 與推文互動？
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎前必須與指定 Twitter
                                    帳戶互動
                                </p>
                                <div>
                                    {[
                                        BUTTON_KEY.TWITTERNONE,
                                        BUTTON_KEY.TWITTERFOLLOW,
                                        BUTTON_KEY.TWITTERINTERACTION,
                                    ].map((v) => (
                                        <button
                                            className={`btn2 ${
                                                v === clickButton.twitter &&
                                                'act'
                                            }`}
                                            key={v}
                                            onClick={() => {
                                                if (
                                                    BUTTON_KEY.TWITTERINTERACTION ===
                                                    v
                                                ) {
                                                    onClickBtn(
                                                        'twitterbtn',
                                                        WHITELIST_MISSION.LIKEANDRETWITTE
                                                    );
                                                }
                                                onClickBtn('twitter', v);
                                            }}
                                        >
                                            {BUTTON_MAP[v]}
                                        </button>
                                    ))}
                                </div>
                                <div className="more-box d-block">
                                    <div className="top">
                                        <p className="bd4-b">
                                            要求抽獎者關注指定帳號
                                            {hintTwitterAccount && (
                                                <span>請輸入英文或數字</span>
                                            )}
                                        </p>
                                        <div className="d-flex ai-center">
                                            <div className="img-box">
                                                <img
                                                    className="cp"
                                                    src="/images/marketBackstage/at2.svg"
                                                    alt="tag"
                                                />
                                            </div>
                                            <div
                                                className="d-flex ai-center"
                                                onClick={() =>
                                                    accountRef.current.focus()
                                                }
                                            >
                                                {mission?.followAccount?.account?.map(
                                                    (v, i) => (
                                                        <span
                                                            key={v}
                                                            className="bd4-n"
                                                            onClick={() =>
                                                                deleteItem(i)
                                                            }
                                                        >
                                                            {v.account}
                                                        </span>
                                                    )
                                                )}
                                            </div>
                                            <input
                                                ref={accountRef}
                                                type="text"
                                                className={
                                                    showInput ? 'act' : ''
                                                }
                                                placeholder={placeholder1}
                                                onChange={(e) => {
                                                    if (
                                                        /[a-z0-9A-Z-_]$/gm.test(
                                                            e.target.value
                                                        )
                                                    ) {
                                                        setHintTwitterAccount(
                                                            false
                                                        );
                                                    } else {
                                                        setHintTwitterAccount(
                                                            true
                                                        );
                                                    }
                                                    if (e.target.value === '') {
                                                        setPlaceholder1(
                                                            '請填寫不包含@的帳號名稱 例：Crypto City'
                                                        );
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (
                                                            /[a-z0-9A-Z-_]$/gm.test(
                                                                e.target.value
                                                            )
                                                        ) {
                                                            addMission(
                                                                WHITELIST_MISSION.FOLLOWACCOUNT,
                                                                'account',
                                                                e.target.value.replace(
                                                                    '@',
                                                                    ''
                                                                )
                                                            );
                                                            e.target.value = '';
                                                            setShowInput(true);
                                                            setPlaceholder1('');
                                                        } else {
                                                            setHintTwitterAccount(
                                                                true
                                                            );
                                                        }
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (
                                                        !/[a-z0-9A-Z-_]$/gm.test(
                                                            e.target.value
                                                        )
                                                    ) {
                                                        return;
                                                    }
                                                    if (e.target.value) {
                                                        addMission(
                                                            WHITELIST_MISSION.FOLLOWACCOUNT,
                                                            'account',
                                                            e.target.value.replace(
                                                                '@',
                                                                ''
                                                            )
                                                        );
                                                        e.target.value = '';
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="left">
                                            <p
                                                className={`bd4-b ${
                                                    hintTwitterCondition
                                                        ? 'hint'
                                                        : ''
                                                }`}
                                            >
                                                要求抽獎者與指定推文互動
                                            </p>
                                            <input
                                                className="bd4-n"
                                                type="text"
                                                placeholder="請填寫推文網址"
                                                onChange={(e) => {
                                                    if (
                                                        /(https?:\/\/)?twitter\.com\/[A-Za-z0-9-_\/]+$/gm.test(
                                                            e.target.value
                                                        )
                                                    ) {
                                                        setHintTwitterCondition(
                                                            false
                                                        );
                                                    } else {
                                                        setHintTwitterCondition(
                                                            true
                                                        );
                                                    }
                                                    addMission(
                                                        'twitterLink',
                                                        'link',
                                                        e.target.value
                                                    );
                                                }}
                                                value={
                                                    mission?.twitterLink?.link
                                                }
                                            />
                                        </div>
                                        <div className="right">
                                            <p className="bd4-b">
                                                達成指定條件
                                            </p>
                                            <div className="d-flex ai-center jc-between">
                                                {/* 點擊後加 act */}
                                                {[
                                                    WHITELIST_MISSION.LIKEPOST,
                                                    WHITELIST_MISSION.RETWEET,
                                                    WHITELIST_MISSION.LIKEANDRETWITTE,
                                                ].map((v) => (
                                                    <button
                                                        className={`btn2 ${
                                                            clickButton.twitterbtn ===
                                                                v && 'act'
                                                        }`}
                                                        key={v}
                                                        onClick={() =>
                                                            onClickBtn(
                                                                'twitterbtn',
                                                                v
                                                            )
                                                        }
                                                    >
                                                        {MISSION_BTN_NAME[v]}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 因IG無法查驗API, 暫不使用 */}
                            {/* instagram */}
                            {/* 加 act 顯示 需要追蹤
                                加 act2 顯示 追蹤並互動
                            */}
                            {/* <IgBox
                                clickButton={clickButton}
                                twitterRef={twitterRef}
                                onClickBtn={onClickBtn}
                                mission={mission}
                                deleteItem={deleteItem}
                                addMission={addMission}
                            /> */}

                            {/* facebook */}
                            {/* 加 act 顯示 需要追蹤
                                加 act2 顯示 追蹤並互動
                            */}
                            {/* <FbBox
                                clickButton={clickButton}
                                facebookRef={facebookRef}
                                onClickBtn={onClickBtn}
                                addMission={addMission}
                                mission={mission}
                                showFbAlert={showFbAlert}
                                hintFbCondition={hintFbCondition}
                                setHintFbCondition={setHintFbCondition}
                            /> */}

                            {/* 錢包 */}
                            {/* 加 act 顯示 所需金額 */}
                            <div
                                className={`wallet-box
                                ${showWalletAlert ? 'red' : ''}
                                ${
                                    clickButton.wallet ===
                                        BUTTON_KEY.WALLETPRICE && 'act'
                                }`}
                                ref={walletRef}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/wallet_black.svg"
                                        alt="wallet"
                                    />
                                    要求抽獎者錢包擁有最低金額
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎時錢包必須持有指定金額，若低於指定金額，將自動取消獲獎資格
                                </p>
                                <div>
                                    {[
                                        BUTTON_KEY.WALLETNONE,
                                        BUTTON_KEY.WALLETPRICE,
                                    ].map((v, i) => (
                                        <button
                                            className={`btn2 ${
                                                clickButton.wallet === v &&
                                                'act'
                                            }`}
                                            key={v}
                                            onClick={() =>
                                                onClickBtn('wallet', v)
                                            }
                                        >
                                            {BUTTON_MAP[v]}
                                        </button>
                                    ))}
                                </div>
                                <div className="more-box">
                                    <div className="top">
                                        <p className="bd4-b">
                                            所需的ETH金額
                                            {hintWalletCondition && (
                                                <span>請輸入正確金額</span>
                                            )}
                                        </p>
                                        <input
                                            type="text"
                                            placeholder="所需的ETH金額"
                                            onChange={(e) => {
                                                if (
                                                    /^\d+(\.\d+)?$/gm.test(
                                                        e.target.value
                                                    )
                                                ) {
                                                    setHintWalletCondition(
                                                        false
                                                    );
                                                } else {
                                                    setHintWalletCondition(
                                                        true
                                                    );
                                                }
                                                addMission(
                                                    WHITELIST_MISSION.CHECKWALLET,
                                                    'min',
                                                    e.target.value
                                                );
                                            }}
                                            value={mission?.checkWallet?.min}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* NFT */}
                            {/* 加 act 顯示 指定ＮＦＴ */}
                            <div
                                className={`NFT-box
                                ${showNftAlert ? 'red' : ''}
                                ${
                                    clickButton.NFT === BUTTON_KEY.NFTREQUIRE &&
                                    'act'
                                } ${form.chainType !== 'SOL' ? '' : 'd-none'}`}
                                ref={NFTRef}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/NFT_black.svg"
                                        alt="NFT"
                                    />
                                    要求抽獎者錢包持有指定NFT
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎時錢包必須持有指定NFT，否則將自動取消獲獎資格。
                                </p>
                                <div>
                                    {[
                                        BUTTON_KEY.NFTNONE,
                                        BUTTON_KEY.NFTREQUIRE,
                                    ].map((v, i) => {
                                        if (
                                            form.chainType !== 'SOL' ||
                                            i !== 1
                                        ) {
                                            return (
                                                <button
                                                    key={`${v}-${i}`}
                                                    className={`btn2 ${
                                                        v === clickButton.NFT &&
                                                        'act'
                                                    }`}
                                                    onClick={() =>
                                                        onClickBtn('NFT', v)
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            );
                                        }
                                    })}
                                </div>
                                <div className="more-box">
                                    <div className="top">
                                        <p className="bd4-b">NFT 名稱</p>
                                        <input
                                            type="text"
                                            placeholder="Genesis Box"
                                            onChange={(e) =>
                                                addMission(
                                                    WHITELIST_MISSION.ChECKNFT,
                                                    'NFTName',
                                                    e.target.value
                                                )
                                            }
                                            value={mission?.checkNFT?.NFTName}
                                        />
                                        <p
                                            className={`bd4-b ${
                                                hintOpenseaCondition
                                                    ? 'hint'
                                                    : ''
                                            }`}
                                        >
                                            Opensea NFT 網址
                                            {hintOpenseaCondition && (
                                                <span>請輸入正確網址</span>
                                            )}
                                        </p>
                                        <input
                                            type="text"
                                            placeholder="https://opensea.io/collection/genesis-box"
                                            onChange={(e) => {
                                                if (
                                                    /^(?:https:\/\/)?opensea\.io\/collection\/+[a-z0-9A-Z-_]+$/gm.test(
                                                        e.target.value
                                                    )
                                                ) {
                                                    setHintOpenseaCondition(
                                                        false
                                                    );
                                                } else {
                                                    setHintOpenseaCondition(
                                                        true
                                                    );
                                                }
                                                addMission(
                                                    WHITELIST_MISSION.ChECKNFT,
                                                    'NFT',
                                                    e.target.value
                                                );
                                            }}
                                            value={mission?.checkNFT?.NFT}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* question */}
                            {/* 加 act 顯示 指定問題 */}
                            <div
                                className={`question-box
                                ${showQaAlert ? 'red' : ''}
                                ${clickButton.QA === BUTTON_KEY.QA && 'act'}`}
                                ref={QARef}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/question_black.svg"
                                        alt="NFT"
                                    />
                                    要求抽獎者回答內容
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者填寫自定義的問題回答
                                </p>
                                <div>
                                    {[BUTTON_KEY.QANONE, BUTTON_KEY.QA].map(
                                        (v) => (
                                            <button
                                                className={`btn2 ${
                                                    v === clickButton.QA &&
                                                    'act'
                                                }`}
                                                key={v}
                                                onClick={() =>
                                                    onClickBtn('QA', v)
                                                }
                                            >
                                                {BUTTON_MAP[v]}
                                            </button>
                                        )
                                    )}
                                </div>
                                <div className="more-box">
                                    <div className="top">
                                        <p className="bd4-b">
                                            請描述問題（500字元內）
                                        </p>
                                        <input
                                            type="text"
                                            maxLength={500}
                                            placeholder="填寫問題內容"
                                            onChange={(e) =>
                                                addMission(
                                                    WHITELIST_MISSION.QA,
                                                    'question',
                                                    e.target.value
                                                )
                                            }
                                            value={
                                                mission?.[WHITELIST_MISSION.QA]
                                                    ?.question
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* 閱讀文章 */}
                            {/* 加 act 顯示 指定文章 */}
                            {/* <div
                                className={`read-box
                                ${showReadAlert ? 'red' : ''}
                                ${
                                    clickButton.READ_NEWS ===
                                        BUTTON_KEY.READ_NEWS && 'act'
                                }`}
                                ref={readRef}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/read_black.svg"
                                        alt="NFT"
                                    />
                                    要求抽獎者閱讀文章
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者閱讀指定文章內容
                                </p>
                                <div>
                                    {[
                                        BUTTON_KEY.READ_NONE,
                                        BUTTON_KEY.READ_NEWS,
                                    ].map((v) => (
                                        <button
                                            className={`btn2 ${
                                                v === clickButton.READ_NEWS &&
                                                'act'
                                            }`}
                                            key={v}
                                            onClick={() =>
                                                onClickBtn('READ_NEWS', v)
                                            }
                                        >
                                            {BUTTON_MAP[v]}
                                        </button>
                                    ))}
                                </div>
                                <div className="more-box d-block">
                                    <div className="top">
                                        <p className="bd4-b">請選擇文章</p>
                                        <div className="article">
                                            <img
                                                className={showArticle && 'act'}
                                                src="/images/marketBackstage/down_arrow.svg"
                                                alt="arrow"
                                            />
                                            <input
                                                placeholder="請輸入文章ID或關鍵字"
                                                value={article}
                                                onFocus={() =>
                                                    setShowArticle(true)
                                                }
                                                onBlur={() =>
                                                    setShowArticle(false)
                                                }
                                            />
                                            <ul
                                                className={`d-flex flex-wrap jc-start ${
                                                    showArticle && 'act'
                                                }`}
                                            >
                                                <li>文章1</li>
                                                <li>文章2</li>
                                                <li>文章3</li>
                                                <li>文章4</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="btns d-flex jc-between">
                                <button
                                    className="btn2 back"
                                    onClick={() => setTab(4)}
                                >
                                    返回修改
                                </button>
                                <button
                                    className="btn2 save"
                                    onClick={() => checkMission()}
                                >
                                    儲存
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* 6.確認條件詳情 */}
                {tab === 6 && (
                    <div className="confirm-condition set-box">
                        <h1 className="h1">確認條件詳情</h1>

                        <div className="inner">
                            {/* discord */}
                            <div
                                className={`dc-box ${
                                    clickButton.discord ===
                                    BUTTON_KEY.DISCORDMEMBER
                                        ? 'act'
                                        : clickButton.discord ===
                                          BUTTON_KEY.DSICORDROLE
                                        ? 'act2'
                                        : ''
                                }`}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/discord_black.svg"
                                        alt="discord"
                                    />
                                    是否需要加入指定 Discord & 取得指定身份？
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎前必須成為您的
                                    Discord 會員或擁有指定身份
                                </p>
                                <div>
                                    <button className="btn2">
                                        {BUTTON_MAP[clickButton.discord]}
                                    </button>
                                </div>

                                <div className="more-box">
                                    <div className="top">
                                        <h5 className="h5">
                                            指定 加入的 Discord
                                        </h5>
                                        <p className="bd4-b">
                                            Server ID
                                            <a
                                                href="/explanation"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/mark.svg"
                                                    alt="more"
                                                />
                                            </a>
                                        </p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                mission?.gettingQualify
                                                    ?.serverId
                                            }
                                        />
                                        <p className="bd4-b">Server 顯示名稱</p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                mission?.gettingQualify
                                                    ?.serverName
                                            }
                                        />
                                        <p className="bd4-b">Server 連接</p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                mission?.gettingQualify?.DcLink
                                            }
                                        />
                                    </div>

                                    <div className="bottom">
                                        <h5 className="h5">
                                            擁有指定 Discord 身份
                                        </h5>
                                        <p className="bd4-b">
                                            Role ID
                                            <a
                                                href="/explanation"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/mark.svg"
                                                    alt="more"
                                                />
                                            </a>
                                        </p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                mission?.gettingQualify
                                                    ?.qualifyID
                                            }
                                        />
                                        <p className="bd4-b">Role 顯示標籤</p>
                                        <input
                                            disabled
                                            type="text"
                                            value={
                                                mission?.gettingQualify?.qualify
                                            }
                                        />
                                    </div>

                                    {/* <div className="raffle">
                                        <h5 className="h5">
                                            擁有指定 Discord 身份特殊加成
                                        </h5>
                                        <h6 className="bd4-n">
                                            可設定擁有指定 Discord Role
                                            可獲得額外加成的抽獎券
                                        </h6>
                                        {raffle.map((r, i) => (
                                            <div
                                                className="raffle-box"
                                                key={`raffle-${i}`}
                                            >
                                                <div className="first d-flex ai-center jc-between">
                                                    <div>
                                                        <p className="bd4-b">
                                                            Role ID
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder="987325346758"
                                                            value={
                                                                mission
                                                                    ?.gettingQualify
                                                                    ?.qualifyID
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="bd4-b">
                                                            Role 顯示標籤
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder="加密城市優秀市民"
                                                            value={
                                                                mission
                                                                    ?.gettingQualify
                                                                    ?.qualify
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="bd4-b">
                                                        加成抽獎券數
                                                    </p>
                                                    <input
                                                        type="text"
                                                        placeholder="請填寫純數字單位"
                                                        value={
                                                            mission
                                                                ?.gettingQualify
                                                                ?.qualify
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div> */}
                                </div>
                            </div>

                            {/* twitter */}
                            <div
                                className={`twitter-box ${
                                    clickButton.twitter ===
                                    BUTTON_KEY.TWITTERFOLLOW
                                        ? 'act'
                                        : clickButton.twitter ===
                                          BUTTON_KEY.TWITTERINTERACTION
                                        ? 'act2'
                                        : ''
                                }`}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/twitter_black.svg"
                                        alt="twitter"
                                    />
                                    是否需要追蹤 Twitter & 與推文互動？
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎前必須與指定 Twitter
                                    帳戶互動
                                </p>
                                <div>
                                    <button className="btn2">
                                        {BUTTON_MAP[clickButton.twitter]}
                                    </button>
                                </div>
                                <div className="more-box d-block">
                                    <div className="top">
                                        <p className="bd4-b">
                                            要求抽獎者關注指定帳號
                                        </p>
                                        <div className="d-flex ai-center">
                                            <img
                                                className="cp"
                                                src="/images/marketBackstage/at2.svg"
                                                alt="tag"
                                            />
                                            <div className="d-flex ai-center">
                                                {mission?.followAccount?.account?.map(
                                                    (v, i) => (
                                                        <span
                                                            className="bd4-n"
                                                            key={`${v}-${i}`}
                                                        >
                                                            {v.account}
                                                        </span>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="left">
                                            <p className="bd4-b">
                                                要求抽獎者與指定推文互動
                                            </p>
                                            <input
                                                className="bd4-n"
                                                disabled
                                                type="text"
                                                value={
                                                    mission?.twitterLink?.link
                                                }
                                            />
                                        </div>
                                        <div className="right">
                                            <p className="bd4-b">
                                                達成指定條件
                                            </p>
                                            <div className="d-flex ai-center jc-between">
                                                <button className="btn2">
                                                    {
                                                        MISSION_BTN_NAME[
                                                            clickButton
                                                                .twitterbtn
                                                        ]
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* facebook */}
                            {/* <div
                                className={`facebook-box ${
                                    clickButton.facebook ===
                                    BUTTON_KEY.FB_LIKE_PAGE
                                        ? 'act'
                                        : ''
                                }`}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/facebook_black.svg"
                                        alt="facebook"
                                    />
                                    是否需要按讚 Facebook 粉絲頁？
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎前必須按讚您的
                                    Facebook 粉絲頁
                                </p>
                                <div>
                                    <button className="btn2">
                                        {BUTTON_MAP[clickButton.facebook]}
                                    </button>
                                </div>
                                <div className="more-box d-block">
                                    <div className="top">
                                        <p className="bd4-b">
                                            要求抽獎者按讚粉絲頁
                                        </p>
                                        <div className="d-flex ai-center">
                                            <div
                                                className="d-flex ai-center bd4-n"
                                                style={{ paddingLeft: '20px' }}
                                            >
                                                https://wwww.facebook.com/
                                                {
                                                    mission[
                                                        WHITELIST_MISSION
                                                            .LIKE_FB_PAGE
                                                    ]?.likeFbPageToken
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* 錢包 */}
                            <div
                                className={`wallet-box ${
                                    clickButton.wallet ===
                                        BUTTON_KEY.WALLETPRICE && 'act'
                                }`}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/wallet_black.svg"
                                        alt="wallet"
                                    />
                                    要求抽獎者錢包擁有最低金額
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎時錢包必須持有指定金額，若低於指定金額，將自動取消獲獎資格
                                </p>
                                <div>
                                    <button
                                        className="btn2"
                                        style={{ width: '211px' }}
                                    >
                                        {BUTTON_MAP[clickButton.wallet]}
                                    </button>
                                </div>
                                <div className="more-box">
                                    <div className="top">
                                        <p className="bd4-b">所需的ETH金額</p>
                                        <input
                                            type="text"
                                            disabled
                                            value={mission?.checkWallet?.min}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* NFT */}
                            <div
                                className={`NFT-box ${
                                    clickButton.NFT === BUTTON_KEY.NFTREQUIRE &&
                                    'act'
                                } ${form.chainType !== 'SOL' ? '' : 'd-none'}`}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/NFT_black.svg"
                                        alt="NFT"
                                    />
                                    要求抽獎者錢包持有指定NFT
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者在登記項目抽獎時錢包必須持有指定NFT，否則將自動取消獲獎資格。
                                </p>
                                <div>
                                    <button
                                        className="btn2"
                                        style={{ width: '211px' }}
                                    >
                                        {BUTTON_MAP[clickButton.NFT]}
                                    </button>
                                </div>
                                <div className="more-box">
                                    <div className="top">
                                        <p className="bd4-b">NFT 名稱</p>
                                        <input
                                            type="text"
                                            disabled
                                            value={mission?.checkNFT?.NFTName}
                                        />
                                        <p className="bd4-b">
                                            Opensea NFT 網址
                                        </p>
                                        <input
                                            type="text"
                                            disabled
                                            value={mission?.checkNFT?.NFT}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* question */}
                            <div
                                className={`question-box act${
                                    clickButton.QA === BUTTON_KEY.QA && 'act'
                                }`}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/question_black.svg"
                                        alt="NFT"
                                    />
                                    要求抽獎者回答內容
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者填寫自定義的問題回答
                                </p>
                                <div>
                                    <button
                                        className="btn2"
                                        style={{ width: '211px' }}
                                    >
                                        {BUTTON_MAP[clickButton.QA]}
                                    </button>
                                </div>
                                <div className="more-box">
                                    <div className="top">
                                        <p className="bd4-b">
                                            請描述問題（500字元內）
                                        </p>

                                        <input
                                            type="text"
                                            disabled
                                            value={
                                                mission?.[WHITELIST_MISSION.QA]
                                                    ?.question
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* 閱讀文章 */}
                            {/* <div
                                className="read-box"
                                style={{ marginTop: '58px' }}
                            >
                                <h2 className="h3">
                                    <img
                                        src="/images/marketBackstage/read_black.svg"
                                        alt="NFT"
                                    />
                                    要求抽獎者閱讀文章
                                </h2>
                                <p className="bd4-n">
                                    要求抽獎者閱讀指定文章內容
                                </p>
                                <div>
                                    {[
                                        BUTTON_KEY.READ_NONE,
                                        BUTTON_KEY.READ_NEWS,
                                    ].map((v) => (
                                        <button
                                            className={`btn2 ${
                                                v === clickButton.READ_NEWS &&
                                                'act'
                                            }`}
                                            key={v}
                                            onClick={() =>
                                                onClickBtn('READ_NEWS', v)
                                            }
                                        >
                                            {BUTTON_MAP[v]}
                                        </button>
                                    ))}
                                </div>
                                <div className="more-box d-block">
                                    <div className="top">
                                        <p className="bd4-b">請選擇文章</p>
                                        <div className="article">
                                            <input
                                                disabled
                                                placeholder="請輸入文章ID或關鍵字"
                                                value={article}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <p className="bd1-n alert">
                                ※
                                注意：請確認您填寫的項目資料與條件是否設定正確，項目發佈後將無法進行變更。
                            </p>
                            <div className="btns d-flex jc-end">
                                <button
                                    className="btn2 back"
                                    onClick={() => setTab(5)}
                                >
                                    返回修改
                                </button>
                                <button
                                    className="btn2 draft"
                                    onClick={() => saveDraft()}
                                >
                                    儲存草稿
                                </button>
                                <button
                                    className="btn2 save"
                                    onClick={() => setShowPostPop(true)}
                                >
                                    儲存並發布
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <PostPop
                showPostPop={showPostPop}
                setShowPostPop={setShowPostPop}
                publishProject={publishProject}
            />
            <Success
                showSuccess={showSuccess}
                setShowSuccess={setShowSuccess}
            />
            <div
                className={`confirm-box ${showConfirmPop && 'act2'} 
                ${choosePath !== '/' && 'long'}
            `}
            >
                <p className="p1 bd1-b">{confirmText}</p>
                <p className="bts d-flex ai-center jc-between">
                    <span
                        className="btn2 b1"
                        onClick={() => setShowConfirmPop(false)}
                    >
                        取消
                    </span>
                    <span
                        className="btn2 b2"
                        onClick={() => {
                            setShowConfirmPop(false);
                            navigate(choosePath);
                            if (choosePath !== '/') {
                                setTab(1);
                                setForm({ chainType: 'ETH' });
                                setClickButton({
                                    discord: BUTTON_KEY.DISCORDNONE,
                                    NFT: BUTTON_KEY.NFTNONE,
                                    QA: BUTTON_KEY.QANONE,
                                    twitter: BUTTON_KEY.TWITTERNONE,
                                    wallet: BUTTON_KEY.WALLETNONE,
                                });
                                const dateInputArr =
                                    document.querySelectorAll('input');
                                dateInputArr.forEach((v) => {
                                    v.value = '';
                                });
                            }
                        }}
                    >
                        {choosePath === '/' ? '確認' : '建立新項目'}
                    </span>
                </p>
            </div>
        </>
    );
}
