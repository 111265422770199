/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import TableToExcel from '@linways/table-to-excel';
import Confirm from '../component/Confirm';
import Modify from '../component/Modify';
import moment from '../common/ccMoment';
import Popup from '../component/Popup';

/**
 * 項目列表
 * @returns
 */
export default function ItemList({ project, setProject }) {
    const [originProjects, setOriginProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    const [showModify, setShowModify] = useState();
    const [deleteProjectId, setDeleteProjectId] = useState(null);
    const [tab, setTab] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [winners, setWinners] = useState([]);

    const [itemIndex, setItemIndex] = useState(0);

    const tableRef = useRef(null);

    const findOwnProjects = async () => {
        const { data } = await axios.get('/api/whitelist/find-own-projects');
        const results = data
            .filter((d) => d.projectStatus === 'ing')
            .concat(
                data
                    .filter((d) => d.projectStatus === 'not')
                    .concat(data.filter((d) => d.projectStatus === 'end'))
            );
        setProjects(results);
        setOriginProjects(results);
    };

    const deleteProject = async () => {
        await axios.delete('/api/whitelist/delete-project', {
            data: { id: deleteProjectId },
        });
        setDeleteProjectId(null);
        findOwnProjects();
    };

    const filterList = (type) => {
        setTab(type);
        if (type) {
            setProjects(originProjects.filter((v) => v.projectStatus === type));
        } else {
            setProjects(originProjects);
        }
    };

    useEffect(() => {
        findOwnProjects();
    }, []);

    /** 一旦 project改變也要重整 projects  */
    useEffect(() => {
        findOwnProjects();
    }, [project]);

    const showWinners = async (projecId) => {
        try {
            const { data } = await axios.get(
                `/api/whitelist/${projecId}/winners`
            );
            setWinners(data);
            setShowPopup(true);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className="main-content">
                {/* 1.項目列表 */}
                <ul className="menu-box d-flex ai-center jc-start">
                    {/* 點擊後加上'act' */}
                    <li
                        className={`bd4-b ${!tab && 'act'}`}
                        onClick={() => filterList(null)}
                    >
                        全部項目
                    </li>
                    <li
                        className={`bd4-b ${tab === 'ing' && 'act'}`}
                        onClick={() => filterList('ing')}
                    >
                        正在進行
                    </li>
                    <li
                        className={`bd4-b ${tab === 'end' && 'act'}`}
                        onClick={() => filterList('end')}
                    >
                        已經結束
                    </li>
                    <li
                        className={`bd4-b ${tab === 'not' && 'act'}`}
                        onClick={() => filterList('not')}
                    >
                        未發佈
                    </li>
                </ul>
                <div className="list-box">
                    <div className="list-wrapper d-flex flex-wrap jc-start">
                        {/* 一個項目 */}
                        {projects.map((v, index) => (
                            <div
                                className="single-item"
                                key={`project_${index}`}
                            >
                                {/* 項目圖 */}
                                <Link to={`/confirm/${v.id}`}>
                                    <div className="img-box">
                                        {/* 分類
                                    正在進行 'type-ing'
                                    未發布 'type-not'
                                    已結束 'type-end'
                                */}
                                        <p
                                            className={`type-${v.projectStatus} bd4-b`}
                                        >
                                            {v.projectStatus === 'ing'
                                                ? '正在進行'
                                                : v.projectStatus === 'not'
                                                ? '未發佈'
                                                : '已結束'}
                                        </p>
                                        <img
                                            src={v.image[0].image}
                                            alt="item"
                                        />
                                    </div>
                                </Link>
                                {/* 項目名稱 */}
                                <h4 className="h4 name">{v.title}</h4>
                                {/* 項目資訊 */}
                                <p className="bd4-n">
                                    <span>
                                        日期：
                                        {moment(v.beginTime).format(
                                            'YYYY/MM/DD'
                                        )}{' '}
                                        ~&nbsp;
                                        {moment(v.endTime).format('YYYY/MM/DD')}
                                    </span>
                                    <span>
                                        抽獎時間：
                                        {moment(v.drawTime).format(
                                            'YYYY/MM/DD'
                                        )}
                                    </span>
                                    <span>抽獎名額：{v.quota}</span>
                                </p>
                                <div className="btn-box d-flex ai-center">
                                    {/* 已發佈的 project 是否已提出修改 */}
                                    {v.projectStatus !== 'end' &&
                                        (v.modifyRequire ? (
                                            <button
                                                disabled
                                                className="disabled btn2 apply"
                                                style={{
                                                    cursor: 'default',
                                                    background: '#d6d3d3',
                                                }}
                                            >
                                                已申請修正
                                            </button>
                                        ) : (
                                            <button
                                                className={`btn2 apply ${
                                                    v.status === 'draft' &&
                                                    'delete'
                                                }`}
                                                onClick={() => {
                                                    if (v.status === 'draft') {
                                                        setDeleteProjectId(
                                                            v.id
                                                        );
                                                    } else {
                                                        setProject(v);
                                                    }
                                                }}
                                            >
                                                {v.status === 'draft'
                                                    ? '刪除'
                                                    : '申請修正'}
                                            </button>
                                        ))}
                                    {v.projectStatus === 'end' && (
                                        <button
                                            className="btn2 list"
                                            onClick={() => {
                                                setItemIndex(index);
                                                showWinners(v.id);
                                            }}
                                            style={{
                                                marginLeft: '7px',
                                                width: '30%',
                                            }}
                                        >
                                            中獎名單
                                        </button>
                                    )}
                                    <Link to={`/confirm/${v.id}`}>
                                        <button className="btn2 check">
                                            查看詳細資訊
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* 項目為空值時顯示以下 */}
                    {!projects.length && (
                        <div className="empty">
                            <div>
                                <img
                                    src="/images/marketBackstage/empty.svg"
                                    alt="empty"
                                />
                            </div>
                            <p className="bd5-n">尚無任何項目</p>
                        </div>
                    )}
                </div>
            </div>
            <Confirm
                title={'確定要刪除這個項目嗎？'}
                deleteProjectId={deleteProjectId}
                setDeleteProjectId={setDeleteProjectId}
                deleteProject={deleteProject}
            />
            <Popup showPopup={showPopup} closePopup={() => setShowPopup(false)}>
                <div id="winners">
                    <div className="top-box d-flex ai-center jc-between">
                        <div className="d-flex ai-center jc-between">
                            <img
                                src={projects[itemIndex]?.image[0].image}
                                style={{
                                    width: '118px',
                                    height: '118px',
                                    borderRadius: '12px',
                                    marginRight: '36px',
                                    objectFit: 'cover',
                                }}
                                alt="item"
                            />
                            <h4 className="h4">
                                <p>{projects[itemIndex]?.title}</p>中獎名單
                            </h4>
                            <div className="download d-flex ai-between jc-start flex-wrap">
                                <p className="bd4-n">
                                    抽獎時間：
                                    {moment(
                                        projects[itemIndex]?.drawTime
                                    ).format('YYYY/MM/DD')}
                                </p>
                                <p className="bd4-n">
                                    抽獎名額：{projects[itemIndex]?.quota}
                                </p>
                                <button
                                    className="btn2"
                                    onClick={() =>
                                        // eslint-disable-next-line implicit-arrow-linebreak
                                        TableToExcel.convert(tableRef.current, {
                                            name: '中獎名單.xlsx',
                                            sheet: {
                                                name: '中獎名單',
                                            },
                                        })
                                    }
                                >
                                    下載名單
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="bot-box">
                        {winners && winners.length > 0 ? (
                            winners.map((winner, index) => {
                                return (
                                    <div
                                        className="winner-info"
                                        key={`winner-${index}`}
                                    >
                                        <div className="bd4-b">
                                            <p>DC帳號</p>
                                            <span>{winner.dcName}</span>
                                        </div>
                                        <div className="bd4-b">
                                            <p>Twitter帳號</p>
                                            <span>{winner.twitterName}</span>
                                        </div>
                                        <div className="bd4-b">
                                            <p>錢包</p>
                                            <span>{winner.prizeWallet}</span>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <></>
                        )}

                        {/* 下載用不顯示 */}
                        <table
                            className="winner-info d-none"
                            ref={tableRef}
                            data-cols-width="24,24,45"
                        >
                            <thead></thead>
                            <tbody>
                                <tr className="bd4-b" data-height="40">
                                    <td
                                        data-f-color="ffffff"
                                        data-fill-color="29afcb"
                                        colSpan={3}
                                        data-f-italic={true}
                                        data-f-bold={true}
                                        data-f-sz={30}
                                    >
                                        {projects[itemIndex]?.title}
                                    </td>
                                </tr>
                                <tr className="bd4-b" data-height="32">
                                    <td data-f-bold={true} data-f-sz={18}>
                                        DC帳號
                                    </td>
                                    <td data-f-bold={true} data-f-sz={18}>
                                        Twitter帳號
                                    </td>
                                    <td data-f-bold={true} data-f-sz={18}>
                                        錢包地址
                                    </td>
                                </tr>
                                {winners?.map((winner, index) => {
                                    return (
                                        <tr
                                            className="bd4-b"
                                            data-height="30"
                                            key={`${index}-winner`}
                                        >
                                            <td data-f-sz={15}>
                                                {winner.dcName}
                                            </td>
                                            <td data-f-sz={15}>
                                                {winner.twitterName}
                                            </td>
                                            <td data-f-sz={15}>
                                                {winner.prizeWallet}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
                {/* {winners && winners.length > 0 ? ( */}
                {/* <table style={{ margin: '15px' }}>
                    <tr>
                        <td style={{ paddingLeft: '35px' }}>DC帳號</td>
                        <td style={{ paddingLeft: '35px' }}>Twitter帳號</td>
                        <td style={{ paddingLeft: '35px' }}>錢包</td>
                    </tr>
                    {winners.map((winner, index) => (
                        <tr key={`winner_${index}`}>
                            <td style={{ paddingLeft: '35px' }}>
                                {winner.dcName}
                            </td>
                            <td style={{ paddingLeft: '35px' }}>
                                {winner.twitterName}
                            </td>
                            <td style={{ paddingLeft: '35px' }}>
                                {winner.prizeWallet}
                            </td>
                        </tr>
                    ))}
                </table> */}
                {/* // ) : (
                //     <div>查無資料</div>
                // )} */}
            </Popup>
        </>
    );
}
