/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import DeleteConfirm from '../component/Confirm';
// import Modify from '../component/Modify';
import PostPop from '../component/PostPop';
import Success from '../component/Success';
import { ACCEPT_TYPE, WHITELIST_MISSION } from '../common/constant';

const Confirm = ({ setProjectPop }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState(null);
    const [showPostPop, setShowPostPop] = useState(false);
    const [deleteProjectId, setDeleteProjectId] = useState(null);
    const [showSuccess, setShowSuccess] = useState(['', false]);

    const getProject = async () => {
        const { data } = await axios.get('/api/whitelist/get-own-project', {
            params: { id },
        });
        setLoading(false);
        setProject(data);
    };

    const createMarkup = () => ({
        __html: project.content.replaceAll('\n', '<br/>'),
    });

    const publishProject = async () => {
        await axios.put('/api/whitelist/publish-project', {
            id,
        });
        getProject();
        setShowPostPop(false);
        setShowSuccess(['已成功發佈', true]);
        setTimeout(() => navigate('/'), 2500);
    };

    const deleteProject = async () => {
        await axios.delete('/api/whitelist/delete-project', {
            data: { id: deleteProjectId },
        });
        navigate('/');
    };

    useEffect(() => {
        if (id) getProject();
    }, [id]);

    if (loading) return <></>;
    if (!project) return <></>;

    return (
        <>
            <div className="main-content">
                {/* 防呆流程 */}
                {project.status === 'draft' && (
                    <section id="procedure2" className="d-flex ai-center">
                        <div className="p1">
                            <p className="bd4-n">1.抽獎時間&名額</p>
                        </div>
                        <div className="p2">
                            <p className="bd4-n">2.項目方基本資訊</p>
                        </div>
                        <div className="p3">
                            <p className="bd4-n">3.項目介紹資訊</p>
                        </div>
                        <div className="p4">
                            <p className="bd4-n">4.指定抽獎條件</p>
                        </div>
                        <div className="p5">
                            <p className="bd4-n">5.草稿或發佈</p>
                        </div>
                    </section>
                )}
                <div className="confirm-condition set-box all">
                    <h1 className="h1" style={{ marginBottom: '35px' }}>
                        {project.title}
                    </h1>
                    <div className="inner">
                        <div className="info-block">
                            {/* 項目視覺資訊 */}
                            <div className="main-info">
                                {/* 背景圖 */}
                                <div className="bg-box">
                                    <img
                                        src={project.banner}
                                        alt="background"
                                    />
                                </div>
                                {/* 主視覺 */}
                                <div className="vision-box d-flex jc-start ai-center">
                                    <div className="img-box">
                                        <img
                                            src={project.image[0].image}
                                            alt="item"
                                        />
                                    </div>
                                </div>

                                {/* 發行資訊 */}
                                <div className="item-text ai-center d-flex flex-wrap jc-between">
                                    <p className="bd1-n first">
                                        發行數量：
                                        {project.issue
                                            ? project.issue > 1000
                                                ? `${
                                                      Math.floor(
                                                          (
                                                              project.issue /
                                                              100
                                                          ).toFixed(1)
                                                      ) / 10
                                                  }K`
                                                : project.issue
                                            : 'TBD'}
                                    </p>
                                    <p className="bd1-n first">
                                        鑄造時間：
                                        {project.mintTime
                                            ? moment(project.mintTime).format(
                                                  'YYYY.MM.DD'
                                              )
                                            : 'TBD'}
                                    </p>
                                    <p className="bd1-n first">
                                        開獎時間：
                                        {project.mintTime
                                            ? moment(project.endTime).format(
                                                  'YYYY.MM.DD'
                                              )
                                            : 'TBD'}
                                    </p>
                                    <p className="bd1-n">
                                        抽獎名額：{project.quota}
                                    </p>
                                    <p className="bd1-n">
                                        鑄造價格：
                                        <img
                                            style={{ marginRight: '4px' }}
                                            src={`/images/marketBackstage/${project.chainType}.svg`}
                                            alt="ETH"
                                        />
                                        {project.mintPrice < 0
                                            ? 'TBD'
                                            : project.mintPrice
                                            ? project.mintPrice
                                            : 'Free'}
                                    </p>
                                    <p className="bd1-n link-box">
                                        連結：
                                        {project.officalLink && (
                                            <a
                                                href={project.officalLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/web.svg"
                                                    alt="website"
                                                />
                                            </a>
                                        )}
                                        {project.twitterLink && (
                                            <a
                                                href={project.twitterLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/tw.svg"
                                                    alt="twitter"
                                                />
                                            </a>
                                        )}
                                        {project.discordLink && (
                                            <a
                                                href={project.discordLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/marketBackstage/dc.svg"
                                                    alt="discord"
                                                />
                                            </a>
                                        )}
                                    </p>
                                </div>
                            </div>

                            {/* 兌換獎項 */}
                            <div className="exchange-box d-flex ai-center jc-between">
                                <div className="left d-flex ai-center">
                                    <div className="img-box">
                                        <img
                                            src="/images/marketBackstage/present.svg"
                                            alt="prize"
                                        />
                                    </div>
                                    <h4 className="h6">
                                        兌換獎項：{project.award}
                                    </h4>
                                </div>
                                <p className="bd4-n">目前已獲得抽獎券數：0</p>
                            </div>

                            {/* 領獎方式 */}
                            <div className="accept-box d-flex ai-center jc-start">
                                <h6 className="h6">領獎方式</h6>

                                {ACCEPT_TYPE[project.acceptType] && (
                                    <p className="bd4-b">
                                        {ACCEPT_TYPE[project.acceptType]}
                                    </p>
                                )}
                            </div>

                            {/* 資訊 */}
                            <p
                                className="info-content bd1-n"
                                dangerouslySetInnerHTML={createMarkup()}
                            ></p>
                        </div>
                    </div>

                    <h1 className="h1">抽獎條件</h1>
                    <div className="inner">
                        {/* discord */}
                        <div
                            className={`dc-box ${
                                project.mission.includes('gettingQualify')
                                    ? project.qualifyID
                                        ? 'act2'
                                        : 'act'
                                    : ''
                            } `}
                        >
                            <h2 className="h3">
                                <img
                                    src="/images/marketBackstage/discord_black.svg"
                                    alt="discord"
                                />
                                是否需要加入指定 Discord & 取得指定身份？
                            </h2>
                            <p className="bd4-n">
                                要求抽獎者在登記項目抽獎前必須成為您的 Discord
                                會員或擁有指定身份
                            </p>
                            <div>
                                <button className="btn2">
                                    {project.mission.includes('gettingQualify')
                                        ? project.qualifyID
                                            ? '加入會員並擁有身份'
                                            : '需要加入會員'
                                        : '不需要'}
                                </button>
                            </div>

                            <div className="more-box">
                                <div className="top">
                                    <h5 className="h5">指定 加入的 Discord</h5>
                                    <p className="bd4-b">
                                        Server ID
                                        <a
                                            href="/explanation"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src="/images/marketBackstage/mark.svg"
                                                alt="more"
                                            />
                                        </a>
                                    </p>
                                    <input
                                        disabled
                                        type="text"
                                        value={project?.serverId}
                                    />
                                    <p className="bd4-b">Server 顯示名稱</p>
                                    <input
                                        disabled
                                        type="text"
                                        value={project?.serverName}
                                    />
                                    <p className="bd4-b">Server 連接</p>
                                    <input
                                        disabled
                                        type="text"
                                        value={project?.DcLink}
                                    />
                                </div>

                                <div className="bottom">
                                    <h5 className="h5">
                                        擁有指定 Discord 身份
                                    </h5>
                                    <p className="bd4-b">
                                        Role ID
                                        <a
                                            href="/explanation"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src="/images/marketBackstage/mark.svg"
                                                alt="more"
                                            />
                                        </a>
                                    </p>
                                    <input
                                        disabled
                                        type="text"
                                        value={project?.qualifyID}
                                    />
                                    <p className="bd4-b">Role 顯示標籤</p>
                                    <input
                                        disabled
                                        type="text"
                                        value={project?.qualify}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* twitter */}
                        <div
                            className={`twitter-box ${
                                project.mission.includes('likeAndRetwitte') ||
                                project.mission.includes('likePost') ||
                                project.mission.includes('retweet')
                                    ? 'act2'
                                    : project.mission.includes('followAccount')
                                    ? 'act'
                                    : ''
                            }`}
                        >
                            <h2 className="h3">
                                <img
                                    src="/images/marketBackstage/twitter_black.svg"
                                    alt="twitter"
                                />
                                是否需要追蹤 Twitter & 與推文互動？
                            </h2>
                            <p className="bd4-n">
                                要求抽獎者在登記項目抽獎前必須與指定 Twitter
                                帳戶互動
                            </p>
                            <div>
                                <button className="btn2">
                                    {project.mission.includes(
                                        'likeAndRetwitte'
                                    ) ||
                                    project.mission.includes('likePost') ||
                                    project.mission.includes('retweet')
                                        ? '需要追蹤並互動'
                                        : project.mission.includes(
                                              'followAccount'
                                          )
                                        ? '需要追蹤'
                                        : '不需要'}
                                </button>
                            </div>
                            <div className="more-box d-block">
                                <div className="top">
                                    <p className="bd4-b">
                                        要求抽獎者關注指定帳號
                                    </p>
                                    <div className="d-flex ai-center">
                                        <img
                                            className="cp"
                                            src="/images/marketBackstage/at.svg"
                                            alt="tag"
                                        />
                                        <div className="d-flex ai-center">
                                            {project?.account?.map((v) => (
                                                <span
                                                    className="bd4-n"
                                                    key={`twitterAccount-${v.account}`}
                                                >
                                                    {v.account}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom">
                                    <div className="left">
                                        <p className="bd4-b">
                                            要求抽獎者與指定推文互動
                                        </p>
                                        <input
                                            className="bd4-n"
                                            disabled
                                            type="text"
                                            value={
                                                project?.share?.share ||
                                                project?.like?.like ||
                                                project?.retweet?.retweet
                                            }
                                        />
                                    </div>
                                    <div className="right">
                                        <p className="bd4-b">達成指定條件</p>
                                        <div className="d-flex ai-center jc-between">
                                            <button className="btn2">
                                                {project.mission.includes(
                                                    'likeAndRetwitte'
                                                )
                                                    ? '按讚&轉推'
                                                    : project.mission.includes(
                                                          'likePost'
                                                      )
                                                    ? '按讚'
                                                    : project.mission.includes(
                                                          'retweet'
                                                      ) && '轉推'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* facebook */}
                        {/* <div
                            className={`facebook-box 
                                ${
                                    project.mission.includes(
                                        WHITELIST_MISSION.LIKE_FB_PAGE
                                    )
                                        ? 'act'
                                        : ''
                                }`}
                        >
                            <h2 className="h3">
                                <img
                                    src="/images/marketBackstage/facebook_black.svg"
                                    alt="facebook"
                                />
                                是否需要按讚 Facebook ？
                            </h2>
                            <p className="bd4-n">
                                要求抽獎者在登記項目抽獎前必須按讚您的 Facebook
                                粉絲頁
                            </p>
                            <div>
                                <button className="btn2">
                                    {project.mission.includes(
                                        WHITELIST_MISSION.LIKE_FB_PAGE
                                    )
                                        ? '需要按讚粉絲頁'
                                        : '不需要'}
                                </button>
                            </div>

                            {project.mission.includes(
                                WHITELIST_MISSION.LIKE_FB_PAGE
                            ) && (
                                <div className="more-box d-block">
                                    <div className="top">
                                        <p className="bd4-b">
                                            要求抽獎者按讚粉絲頁
                                        </p>
                                        <div className="d-flex ai-center">
                                            <div
                                                className="d-flex ai-center bd4-n"
                                                style={{ paddingLeft: '20px' }}
                                            >
                                                https://wwww.facebook.com/
                                                {
                                                    project.mission[
                                                        WHITELIST_MISSION
                                                            .LIKE_FB_PAGE
                                                    ]?.likeFbPageToken
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> */}

                        {/* 錢包 */}
                        <div
                            className={`wallet-box ${
                                project.mission.includes('checkWallet') && 'act'
                            }`}
                        >
                            <h2 className="h3">
                                <img
                                    src="/images/marketBackstage/wallet_black.svg"
                                    alt="wallet"
                                />
                                要求抽獎者錢包擁有最低金額
                            </h2>
                            <p className="bd4-n">
                                要求抽獎者在登記項目抽獎時錢包必須持有指定金額，若低於指定金額，將自動取消獲獎資格
                            </p>
                            <div>
                                <button
                                    className="btn2"
                                    style={{ width: '211px' }}
                                >
                                    {project.mission.includes('checkWallet')
                                        ? '需要指定金額'
                                        : '不需要'}
                                </button>
                            </div>
                            <div className="more-box">
                                <div className="top">
                                    <p className="bd4-b">所需的ETH金額</p>
                                    <input
                                        type="text"
                                        disabled
                                        value={project?.min}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* NFT */}
                        <div
                            className={`NFT-box ${
                                project.mission.includes('checkNFT') && 'act'
                            } ${project.chainType === 'SOL' ? 'd-none' : ''}`}
                        >
                            <h2 className="h3">
                                <img
                                    src="/images/marketBackstage/NFT_black.svg"
                                    alt="NFT"
                                />
                                要求抽獎者錢包持有指定NFT
                            </h2>
                            <p className="bd4-n">
                                要求抽獎者在登記項目抽獎時錢包必須持有指定NFT，否則將自動取消獲獎資格。
                            </p>
                            <div>
                                <button
                                    className="btn2"
                                    style={{ width: '211px' }}
                                >
                                    {project.mission.includes('checkNFT')
                                        ? '需要指定NFT'
                                        : '不需要'}
                                </button>
                            </div>
                            <div className="more-box">
                                <div className="top">
                                    <p className="bd4-b">NFT 名稱</p>
                                    <input
                                        type="text"
                                        disabled
                                        value={project?.NFTName}
                                    />
                                    <p className="bd4-b">Opensea NFT 網址</p>
                                    <input
                                        type="text"
                                        disabled
                                        value={
                                            project?.NFT &&
                                            `https://opensea.io/collection/${project?.NFT}`
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        {/* question */}
                        <div
                            className={`question-box ${
                                project.mission.includes('QA') && 'act'
                            }`}
                        >
                            <h2 className="h3">
                                <img
                                    src="/images/marketBackstage/question_black.svg"
                                    alt="NFT"
                                />
                                要求抽獎者回答內容
                            </h2>
                            <p className="bd4-n">
                                要求抽獎者填寫自定義的問題回答
                            </p>
                            <div>
                                <button
                                    className="btn2"
                                    style={{ width: '211px' }}
                                >
                                    {project.mission.includes('QA')
                                        ? '必須回答指定問題'
                                        : '不需要'}
                                </button>
                            </div>
                            <div className="more-box">
                                <div className="top">
                                    <p className="bd4-b">
                                        請描述問題（500字元內）
                                    </p>

                                    <input
                                        type="text"
                                        disabled
                                        value={project?.question}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="btns-box d-flex jc-between btns-box2">
                            <Link to={'/'}>
                                <button className="btn2 back">返回列表</button>
                            </Link>
                            {project.status === 'draft' ? (
                                <>
                                    <button
                                        className="btn2 delete"
                                        onClick={() =>
                                            setDeleteProjectId(project.id)
                                        }
                                    >
                                        刪除
                                    </button>
                                    <Link to={`/new-item/edit/${project.id}`}>
                                        <button className="btn2 edit">
                                            修改
                                        </button>
                                    </Link>
                                    <button
                                        className="btn2 post"
                                        onClick={() => setShowPostPop(true)}
                                    >
                                        發佈此項目
                                    </button>
                                </>
                            ) : (
                                <button
                                    className="btn2 fix"
                                    onClick={() => setProjectPop(project)}
                                >
                                    申請修正
                                </button>
                            )}
                        </div>
                        <DeleteConfirm
                            title={'確定要刪除這個項目嗎？'}
                            deleteProjectId={deleteProjectId}
                            setDeleteProjectId={setDeleteProjectId}
                            deleteProject={deleteProject}
                        />
                    </div>
                </div>
            </div>
            <PostPop
                showPostPop={showPostPop}
                setShowPostPop={setShowPostPop}
                publishProject={publishProject}
            />
            <Success
                setShowSuccess={setShowSuccess}
                showSuccess={showSuccess}
            />
        </>
    );
};

export default Confirm;
