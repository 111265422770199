import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import '../styles/backstage.scss';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';

/**
 * 包含左側 SideBar 及 上方選單(全部/正在進行/已經結束/未發佈)
 * @returns
 */
export default function Home({
    setShowConfirmPop,
    setChoosePath,
    setConfirmText,
}) {
    const { logout, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const onLogout = async () => {
        await axios.get('/api/auth/logout');
        logout();
    };

    const getUserImg200 = user.image.replace('_normal', '_200x200');

    return (
        <div className="main">
            <aside>
                <div className="inner d-flex flex-column ai-center jc-start">
                    <Link
                        onClick={() => {
                            if (location.pathname.includes('new-item')) {
                                setConfirmText('資料尚未儲存，確定要離開嗎？');
                                setShowConfirmPop(true);
                                setChoosePath('/');
                            } else {
                                navigate('//');
                            }
                        }}
                    >
                        <div className="img-box">
                            <img
                                src="/images/marketBackstage/logo-sm.svg"
                                alt="logo"
                            />
                        </div>
                    </Link>
                    {/* 頭像 */}
                    <div className="thumbnail">
                        <img src={getUserImg200} alt="thumbnail" />
                    </div>
                    <h6 className="btn1">{user.nickname}</h6>

                    <div
                        className="log-out d-flex ai-center cp"
                        onClick={onLogout}
                    >
                        <div>
                            <img
                                alt="log-out"
                                src="/images/marketBackstage/logout.svg"
                            />
                        </div>
                        <span className="btn2">登出</span>
                    </div>

                    <Link
                        to="/new-item/add"
                        onClick={() => {
                            if (location.pathname.includes('new-item')) {
                                setConfirmText(
                                    '資料尚未儲存，確定要建立新項目？'
                                );
                                setShowConfirmPop(true);
                                setChoosePath('/new-item/add');
                            }
                        }}
                    >
                        <button className="btn2 est-btn">建立新項目</button>
                    </Link>
                    <button
                        className="btn2 list-bnt"
                        onClick={() => {
                            if (location.pathname.includes('new-item')) {
                                setConfirmText('資料尚未儲存，確定要離開嗎？');
                                setShowConfirmPop(true);
                                setChoosePath('/');
                            } else {
                                navigate('/item-list', { replace: true });
                            }
                        }}
                    >
                        項目列表
                    </button>
                    <p className="bd4-n">© 2023 Crypto City.</p>
                </div>
            </aside>
            <Outlet />
        </div>
    );
}
