export const BUTTON_KEY = {
    DISCORDNONE: 'discordNone',
    DISCORDMEMBER: 'discrodMember',
    DSICORDROLE: 'discordRole',
    TWITTERNONE: 'TwitterNone',
    TWITTERFOLLOW: 'TwitterFollow',
    TWITTERINTERACTION: 'TwitterInteraction',
    INSTAGRAMNONE: 'InstagramNone',
    INSTAGRAMFOLLOW: 'InstagramFollow',
    INSTAGRAMINTERACTION: 'InstagramInteraction',
    FACEBOOKNONE: 'FacebookNone',
    FACEBOOKFOLLOW: 'FacebookFollow',
    FACEBOOKINTERACTION: 'FacebookInteraction',
    WALLETNONE: 'WalletNone',
    WALLETPRICE: 'WallePrice',
    NFTNONE: 'NFTNone',
    NFTREQUIRE: 'NFTRequire',
    QANONE: 'QANone',
    QA: 'QA',
    /** 不需要FB互動 */
    FB_NONE: 'FbNone',
    /** 按讚FB粉絲頁 */
    FB_LIKE_PAGE: 'FbLikePage',
    READ_NONE: 'ReadNone',
    READ_NEWS: 'ReadNews',
};

export const WHITELIST_MISSION = {
    BINDINGDC: 'bindingDC',
    GETTINGQUALIFY: 'gettingQualify',
    BINDINGTWITTER: 'bindingTwitter',
    BINDINGINSTAGRAM: 'bindingInstagram',
    BINDINGFACEBOOK: 'bindingFacebook',
    FOLLOWACCOUNT: 'followAccount',
    LIKEPOST: 'likePost',
    RETWEET: 'retweet',
    LIKEANDRETWITTE: 'likeAndRetwitte',
    CHECKWALLET: 'checkWallet',
    ChECKNFT: 'checkNFT',
    QA: 'QA',
    /** 綁定 FB */
    BINDING_FB: 'bindingFb',
    /** 按讚 FB 粉絲團 */
    LIKE_FB_PAGE: 'likeFbPage',
    READ_NEWS: 'readNEws',
};

export const MISSION_BTN_NAME = {
    [WHITELIST_MISSION.LIKEPOST]: '按讚',
    [WHITELIST_MISSION.RETWEET]: '轉推',
    [WHITELIST_MISSION.LIKEANDRETWITTE]: '按讚＆轉推',
};

export const BUTTON_MAP = {
    [BUTTON_KEY.DISCORDNONE]: '不需要',
    [BUTTON_KEY.DISCORDMEMBER]: '需要加入會員',
    [BUTTON_KEY.DSICORDROLE]: '加入會員並擁有身份',
    [BUTTON_KEY.TWITTERNONE]: '不需要',
    [BUTTON_KEY.TWITTERFOLLOW]: '需要追蹤',
    [BUTTON_KEY.TWITTERINTERACTION]: '需要追蹤並互動',
    [BUTTON_KEY.INSTAGRAMNONE]: '不需要',
    [BUTTON_KEY.INSTAGRAMFOLLOW]: '需要追蹤',
    [BUTTON_KEY.INSTAGRAMINTERACTION]: '需要追蹤並互動',
    [BUTTON_KEY.FACEBOOKNONE]: '不需要',
    [BUTTON_KEY.FACEBOOKFOLLOW]: '需要追蹤',
    [BUTTON_KEY.FACEBOOKINTERACTION]: '需要追蹤並互動',
    [BUTTON_KEY.WALLETNONE]: '不需要',
    [BUTTON_KEY.WALLETPRICE]: '需要指定金額',
    [BUTTON_KEY.NFTNONE]: '不需要',
    [BUTTON_KEY.NFTREQUIRE]: '需求指定NFT',
    [BUTTON_KEY.QANONE]: '不需要',
    [BUTTON_KEY.QA]: '必須回答指定問題',
    [BUTTON_KEY.FB_NONE]: '不需要',
    [BUTTON_KEY.FB_LIKE_PAGE]: '需要按讚粉絲頁',
    [BUTTON_KEY.READ_NONE]: '不需要',
    [BUTTON_KEY.READ_NEWS]: '必須閱讀指定文章',
};

export const ACCEPT_TYPE = {
    ROLE: 'Role',
    DTC: 'DTC',
    OPEN: 'Open Ticket',
};
