import { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import Home from './page/Home';
import ItemList from './page/ItemList';
import NewItem from './page/NewItem';
import ConfrimPage from './page/Confrim';
import Explanation from './Explanation';
import { useAuth } from './hooks/useAuth';
import Modify from './component/Modify';

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
        // user is not authenticated
        return <Navigate to="/login" />;
    }

    return children;
};

const App = () => {
    const { login, logout } = useAuth();
    const [project, setProject] = useState(null);
    const [showConfirmPop, setShowConfirmPop] = useState(false);
    const [choosePath, setChoosePath] = useState('/');
    const [confirmText, setConfirmText] = useState('');

    const fetchUser = async () => {
        const { data } = await axios.get('/api/auth/user');
        const { user } = data;
        if (user) {
            login(user);
        } else {
            logout();
        }
    };

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <main id="home" className="d-flex jc-start">
                <Modify project={project} setProject={setProject} />
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Login />} />
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Home
                                    setShowConfirmPop={setShowConfirmPop}
                                    setChoosePath={setChoosePath}
                                    setConfirmText={setConfirmText}
                                />
                            </ProtectedRoute>
                        }
                    >
                        <Route
                            index
                            element={<ItemList project={project} setProject={setProject} />}
                        />
                        <Route
                            path="/new-item/add"
                            element={
                                <NewItem
                                    showConfirmPop={showConfirmPop}
                                    choosePath={choosePath}
                                    setShowConfirmPop={setShowConfirmPop}
                                    confirmText={confirmText}
                                />
                            }
                        />
                        <Route
                            path="/new-item/edit/:id"
                            element={
                                <NewItem
                                    showConfirmPop={showConfirmPop}
                                    choosePath={choosePath}
                                    setShowConfirmPop={setShowConfirmPop}
                                    confirmText={confirmText}
                                />
                            }
                        />
                        <Route
                            path="/confirm/:id"
                            element={<ConfrimPage setProjectPop={setProject} />}
                        />
                        <Route
                            path="/item-list"
                            element={<ItemList setProject={setProject} />}
                        />
                        <Route path="/explanation" element={<Explanation />} />
                        <Route
                            path="*"
                            element={
                                <main>
                                    <p>頁面不存在</p>
                                </main>
                            }
                        />
                    </Route>
                </Routes>
            </main>
        </div>
    );
};

export default App;
