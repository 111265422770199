import React, { useEffect } from 'react';
import Lottie from 'react-lottie-player';
import VerifiedAnimation from '../verified.json';

export default function Success({ showSuccess, setShowSuccess }) {
    useEffect(() => {
        if (showSuccess[1]) setTimeout(() => setShowSuccess(['', false]), 3000);
    }, [showSuccess]);

    return (
        <>
            {showSuccess[1] && (
                <div id="success-box">
                    <div>
                        <Lottie
                            loop={false}
                            speed={1}
                            play={true}
                            animationData={VerifiedAnimation}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                background: 'transparent',
                            }}
                        />
                    </div>
                    <p
                        className="bd1-b"
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {showSuccess[0]}
                    </p>
                </div>
            )}
        </>
    );
}
