import { Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

import './styles/login.scss';

/**
 * 登入頁面
 * @returns
 */
export default function Login() {

    const { user } = useAuth();

    // 若已經登入，返回首頁
    if (user) {
        return <Navigate to="/" />;
    }

    const onSignin = () => {
        window.location = '/api/auth/signin/twitter';
    };

    return (
        <main id="backstage-login">
            {/* 背景圖 */}
            <div className="bg-box">
                <img
                    src="/images/marketBackstage/background.png"
                    alt="background"
                />
            </div>
            <div className="content-wrapper">
                {/* logo 圖 */}
                <div className="logo-box">
                    <img src="/images/marketBackstage/logo.svg" alt="logo" />
                </div>
                {/* 登入框 */}
                <div className="login-block">
                    <div className="img-box">
                        <img
                            src="/images/marketBackstage/login.svg"
                            alt="login"
                        />
                    </div>
                    <h6 className="bd4-n">
                        HI，歡迎來到 Crypto City 白單上架系統
                    </h6>
                    <p className="bd4-b">帳號登入</p>
                    <div
                        className="twitter-login cp d-flex ai-center jc-center"
                        onClick={() => onSignin()}
                    >
                        <div className="img-box">
                            <img
                                className="t1"
                                src="/images/marketBackstage/twitter.svg"
                                alt="twitter"
                            />
                            <img
                                className="t2"
                                src="/images/marketBackstage/twitter_white.svg"
                                alt="twitter"
                            />
                        </div>

                        <span className="btn2">使用 Twitter 登入</span>
                    </div>

                    <div className="return-box d-flex ai-center jc-between">
                        <a
                            href="https://www.cryptocity.tw/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className="btn1">← 返回官網</button>
                        </a>

                        <a
                            href="https://www.cryptocity.tw/cooperation"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className="btn1">登入遇到困難？</button>
                        </a>
                    </div>
                </div>

                <h4 className="bd4-n">Copyright © 2023 Crypto City.</h4>
            </div>
        </main>
        // <div>
        //     <h1>Login Page</h1>
        //     <a href={'/api/auth/signin/twitter'}>
        //         Signin via twitter
        //     </a>
        //     <button onClick={onSignin}>Signin via twitter</button>
        // </div>
    );
}
