/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
    BUTTON_KEY,
    BUTTON_MAP,
    WHITELIST_MISSION,
    MISSION_BTN_NAME,
    ACCEPT_TYPE,
} from '../common/constant';
import '../styles/popout.scss';
import moment from '../common/ccMoment';
// eslint-disable-next-line import/order
import DatePicker, { registerLocale } from 'react-datepicker';
// eslint-disable-next-line quotes
import 'react-datepicker/dist/react-datepicker.css';
// eslint-disable-next-line import/order
import zh from 'date-fns/esm/locale/zh-TW';

registerLocale('zh', zh);

export default function Modify({ project, setProject }) {
    const [step, setStep] = useState(1);
    const [clickButton, setClickButton] = useState({});
    const [mission, setMission] = useState({});
    const [form, setForm] = useState({});
    const [saveSuccessly, setSaveSuccessly] = useState(false);
    const [draftMission, setDraftMission] = useState({});
    const [showInput, setShowInput] = useState(false);
    const [placeholder1, setPlaceholder1] = useState(
        '請填寫不包含@的帳號名稱 例：Crypto City'
    );
    const [hintEmail, setHintEmail] = useState(false);
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [hasTime, setHasTime] = useState(false);
    const [raffle, setRaffle] = useState([1]);
    const [article, setArticle] = useState('');
    const [showArticle, setShowArticle] = useState(false);
    const accountRef = useRef(null);

    const onClickBtn = (category, value) => {
        setClickButton({ ...clickButton, [category]: value });
    };

    const onChange = (key, value) => {
        setForm({ ...form, [key]: value });
    };

    const addMission = (missionName, key, value) => {
        if (missionName === 'followAccount') {
            const newMission = { ...mission };
            const insertData = { [key]: value };
            if (newMission[missionName] && newMission[missionName][key]) {
                newMission[missionName][key].push(insertData);
            } else {
                newMission[missionName] = { [key]: [insertData] };
            }
            setMission(newMission);

            return;
        }
        setMission({
            ...mission,
            [missionName]: { ...mission[missionName], [key]: value },
        });
    };

    const deleteItem = (index) => {
        const newMission = { ...mission };
        newMission.followAccount.account =
            newMission.followAccount.account.filter((v, i) => i !== index);
        setMission(newMission);
    };

    const checkMission = () => {
        const { discord, NFT, QA, twitter, twitterbtn, wallet } = clickButton;
        const {
            gettingQualify,
            followAccount,
            twitterLink,
            checkNFT,
            checkWallet,
        } = mission;
        const newMission = {};
        if (!discord || !NFT || !QA || !twitter || !wallet) {
            return alert('有條件未選擇');
        }
        // 檢查discord條件
        if (discord !== BUTTON_KEY.DISCORDNONE) {
            const { DcLink, qualify, serverName, serverId, qualifyID } =
                gettingQualify || {};
            const newData = { DcLink, serverId, serverName };
            if (!gettingQualify || !DcLink || !serverId || !serverName) {
                return alert('須設定discord條件');
            }
            if (discord === BUTTON_KEY.DSICORDROLE) {
                if (!qualify || !qualifyID) {
                    return alert('須設定discord條件');
                }
                newData.qualify = qualify;
                newData.qualifyID = qualifyID;
            }
            newMission.gettingQualify = newData;
        }
        // 檢查twitter條件
        if (twitter !== BUTTON_KEY.TWITTERNONE) {
            const { account } = followAccount || {};
            if (!account) {
                return alert('要求追蹤Twitter帳號未填');
            }
            if (twitter === BUTTON_KEY.TWITTERINTERACTION) {
                if (!twitterbtn || !twitterLink) {
                    return alert('未選擇互動條件或未填推文網址');
                }
                let missionKey;
                switch (twitterbtn) {
                    case WHITELIST_MISSION.LIKEPOST:
                        missionKey = 'like';
                        break;
                    case WHITELIST_MISSION.RETWEET:
                        missionKey = 'retweet';
                        break;
                    case WHITELIST_MISSION.LIKEANDRETWITTE:
                        missionKey = 'share';
                        break;
                    default:
                        break;
                }
                newMission.followAccount = followAccount;
                newMission[twitterbtn] = {
                    [missionKey]: [{ [missionKey]: twitterLink.link }],
                };
            }
        }
        // // 檢查錢包條件
        if (wallet !== BUTTON_KEY.WALLETNONE) {
            if (!checkWallet.min) {
                return alert('所需ETH尚未填寫');
            }
            const newData = {
                ...checkWallet,
                coinType: 'ETH',
                walletType: 'ETH',
            };
            newMission.checkWallet = newData;
        }
        // 檢查NFT條件
        if (NFT !== BUTTON_KEY.NFTNONE) {
            if (!checkNFT?.NFTName) return alert('NFT 名稱未填');
            const newCheckNFT = checkNFT?.NFT.split('/')[4];
            if (!newCheckNFT) return alert('Opensea NFT 網址未填或格式錯誤');
            const newData = {
                ...checkNFT,
                NFT: newCheckNFT,
                NFTWalletType: 'ETH',
            };
            newMission.checkNFT = newData;
        }
        // 檢查QA條件
        if (QA !== BUTTON_KEY.QANONE) {
            if (!mission?.QA?.question) return alert('描述問題未填寫');
            newMission.QA = { question: mission.QA.question };
        }
        setDraftMission(newMission);

        return setStep(0);
    };

    const onSubmit = async () => {
        // const { discord, NFT, QA, twitter, twitterbtn, wallet } = clickButton;
        // const {
        //     gettingQualify,
        //     followAccount,
        //     twitterLink,
        //     checkNFT,
        //     checkWallet,
        // } = mission;
        // const newMission = {};
        // if (!discord || !NFT || !QA || !twitter || !wallet) {
        //     return alert('有條件未選擇');
        // }
        // // 檢查discord條件
        // if (discord !== BUTTON_KEY.DISCORDNONE) {
        //     const { DcLink, qualify, serverName, serverId, qualifyID } =
        //         gettingQualify || {};
        //     const newData = { DcLink, serverId, serverName };
        //     if (!gettingQualify || !DcLink || !serverId || !serverName) {
        //         return alert('須設定discord條件');
        //     }
        //     if (discord === BUTTON_KEY.DSICORDROLE) {
        //         if (!qualify || !qualifyID) {
        //             return alert('須設定discord條件');
        //         }
        //         newData.qualify = qualify;
        //         newData.qualifyID = qualifyID;
        //     }
        //     newMission.gettingQualify = newData;
        // }
        // // 檢查twitter條件
        // if (twitter !== BUTTON_KEY.TWITTERNONE) {
        //     const { account } = followAccount || {};
        //     if (!account) {
        //         return alert('要求追蹤Twitter帳號未填');
        //     }
        //     if (twitter === BUTTON_KEY.TWITTERINTERACTION) {
        //         if (!twitterbtn || !twitterLink) {
        //             return alert('未選擇互動條件或未填推文網址');
        //         }
        //         let missionKey;
        //         switch (twitterbtn) {
        //             case WHITELIST_MISSION.LIKEPOST:
        //                 missionKey = 'like';
        //                 break;
        //             case WHITELIST_MISSION.RETWEET:
        //                 missionKey = 'retweet';
        //                 break;
        //             case WHITELIST_MISSION.LIKEANDRETWITTE:
        //                 missionKey = 'share';
        //                 break;
        //             default:
        //                 break;
        //         }
        //         newMission.followAccount = followAccount;
        //         newMission[twitterbtn] = {
        //             [missionKey]: { [missionKey]: twitterLink.link },
        //         };
        //     }
        // }
        // // // 檢查錢包條件
        // if (wallet !== BUTTON_KEY.WALLETNONE) {
        //     if (!checkWallet.min) {
        //         return alert('所需ETH尚未填寫');
        //     }
        //     const newData = {
        //         ...checkWallet,
        //         coinType: 'ETH',
        //         walletType: 'ETH',
        //     };
        //     newMission.checkWallet = newData;
        // }
        // // 檢查NFT條件
        // if (NFT !== BUTTON_KEY.NFTNONE) {
        //     if (!checkNFT?.NFTName) return alert('NFT 名稱未填');
        //     const newCheckNFT = checkNFT?.NFT.split('/')[4];
        //     if (!newCheckNFT) return alert('Opensea NFT 網址未填或格式錯誤');
        //     const newData = {
        //         ...checkNFT,
        //         NFT: newCheckNFT,
        //         NFTWalletType: 'ETH',
        //     };
        //     newMission.checkNFT = newData;
        // }
        // // 檢查QA條件
        // if (QA !== BUTTON_KEY.QANONE) {
        //     if (!mission?.QA?.question) return alert('描述問題未填寫');
        //     newMission.QA = { question: mission.QA.question };
        // }
        const { data } = await axios.post(
            '/api/whitelist/save-modify-require',
            {
                ...form,
                mission: JSON.stringify(draftMission),
                id: null,
                projectId: form.id,
                status: 'undone',
            }
        );

        if (data.insertId) {
            setSaveSuccessly(true);
        }

        // 完成申請後重整 itemList projects
        // setProject(null);

        return data;
    };

    useEffect(() => {
        if (!project) setStep(1);
        else {
            setForm(project);
            const {
                qualifyID,
                serverId,
                account,
                share,
                like,
                retweet,
                min,
                NFTName,
                question,
                DcLink,
                qualify,
                serverName,
                likeFbPageToken,
            } = project;
            let discord;
            let NFT;
            let QA;
            let twitter;
            let twitterbtn;
            let wallet;
            let facebook;
            const missionOutput = {
                gettingQualify: {
                    DcLink,
                    qualify,
                    serverName,
                    qualifyID,
                    serverId,
                },
                followAccount: {
                    account,
                },
                checkWallet: {
                    min,
                },
                checkNFT: {
                    NFTName,
                    NFT: `https://opensea.io/collection/${project.NFT}`,
                },
                QA: {
                    question,
                },
                likeFbPage: {
                    likeFbPageToken,
                },
            };
            if (qualifyID) {
                discord = BUTTON_KEY.DSICORDROLE;
            } else if (serverId) {
                discord = BUTTON_KEY.DISCORDMEMBER;
            } else {
                discord = BUTTON_KEY.DISCORDNONE;
            }
            if (share || like || retweet) {
                twitter = BUTTON_KEY.TWITTERINTERACTION;
                if (share) {
                    twitterbtn = WHITELIST_MISSION.LIKEANDRETWITTE;
                    missionOutput.twitterLink = {
                        link: share.share,
                    };
                } else if (like) {
                    twitterbtn = WHITELIST_MISSION.LIKEPOST;
                    missionOutput.twitterLink = {
                        link: like.like,
                    };
                } else if (retweet) {
                    twitterbtn = WHITELIST_MISSION.RETWEET;
                    missionOutput.twitterLink = {
                        link: retweet.retweet,
                    };
                }
            } else if (account) {
                twitter = BUTTON_KEY.TWITTERFOLLOW;
            } else {
                twitter = BUTTON_KEY.TWITTERNONE;
            }
            if (min) {
                wallet = BUTTON_KEY.WALLETPRICE;
            } else {
                wallet = BUTTON_KEY.WALLETNONE;
            }
            if (NFTName) {
                NFT = BUTTON_KEY.NFTREQUIRE;
            } else {
                NFT = BUTTON_KEY.NFTNONE;
            }
            if (question) {
                QA = BUTTON_KEY.QA;
            } else {
                QA = BUTTON_KEY.QANONE;
            }
            if (likeFbPageToken) {
                facebook = BUTTON_KEY.FB_LIKE_PAGE;
            } else {
                facebook = BUTTON_KEY.FB_NONE;
            }
            setClickButton({
                discord,
                NFT,
                QA,
                twitter,
                twitterbtn,
                wallet,
                facebook,
            });
            setMission(missionOutput);
        }
    }, [project]);

    return (
        project && (
            <div className={'modify-wrapper act'}>
                <p className="shadow" onClick={() => setProject(null)}></p>
                {/* 變更清單 */}
                <div className={`modify-block ${!step ? 'd-none' : ''}`}>
                    <div className="modify-box">
                        <h1 className="h1">想要變更什麼呢？</h1>

                        {/* 抽獎時間 */}
                        <div
                            className={`lot-time ${step === 2 && 'act'}`}
                            onClick={() => setStep(2)}
                        >
                            <h2 className="bd4-b">抽獎時間</h2>
                            {/* inner 加 act 展開 */}
                            <div className="inner">
                                <p className="bd4-b must">抽獎開始時間</p>
                                <DatePicker
                                    timeFormat="HH:mm"
                                    showTimeSelect
                                    locale="zh"
                                    timeIntervals={10}
                                    selected={
                                        form.beginTime &&
                                        new Date(form.beginTime)
                                    }
                                    onChange={(date) => {
                                        setStart(date);
                                        onChange('beginTime', date);
                                    }}
                                    dateFormat="yyyy/MM/dd  HH:mm"
                                />
                                <p className="bd4-b must">抽獎開始時間</p>
                                <DatePicker
                                    timeFormat="HH:mm"
                                    showTimeSelect
                                    timeIntervals={10}
                                    selected={
                                        form.endTime && new Date(form.endTime)
                                    }
                                    locale="zh"
                                    onChange={(date) => {
                                        setEnd(date);
                                        onChange('endTime', date);
                                    }}
                                    minDate={start}
                                    dateFormat="yyyy/MM/dd  HH:mm"
                                />
                            </div>
                        </div>

                        {/* 抽獎名額 */}
                        <div
                            className={`lot-number ${step === 3 && 'act'}`}
                            onClick={() => setStep(3)}
                        >
                            <h2 className="bd4-b">抽獎名額</h2>
                            <div className="inner">
                                <input
                                    type="text"
                                    placeholder="100"
                                    onChange={(e) =>
                                        onChange('quota', e.target.value)
                                    }
                                    value={form.quota}
                                />
                            </div>
                        </div>

                        {/* 抽獎條件 */}
                        <div
                            className={`lot-condition ${step === 4 && 'act'}`}
                            onClick={() => setStep(4)}
                        >
                            <h2 className="bd4-b">抽獎條件</h2>
                            <div className="set-box">
                                <div className="inner">
                                    <div
                                        className={`dc-box ${
                                            clickButton.discord ===
                                            BUTTON_KEY.DISCORDMEMBER
                                                ? 'act'
                                                : clickButton.discord ===
                                                  BUTTON_KEY.DSICORDROLE
                                                ? 'act2'
                                                : ''
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/discord_black.svg"
                                                alt="discord"
                                            />
                                            是否需要加入指定 Discord &
                                            取得指定身份？
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者在登記項目抽獎前必須成為您的
                                            Discord 會員或擁有指定身份
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.DISCORDNONE,
                                                BUTTON_KEY.DISCORDMEMBER,
                                                BUTTON_KEY.DSICORDROLE,
                                            ].map((v, i) => (
                                                <button
                                                    key={`${v}-${i}`}
                                                    className={`btn2 ${
                                                        v ===
                                                            clickButton.discord &&
                                                        'act'
                                                    }`}
                                                    onClick={() =>
                                                        onClickBtn('discord', v)
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>

                                        <div className="more-box">
                                            <div className="top">
                                                <h5 className="h5">
                                                    指定 加入的 Discord
                                                </h5>
                                                <p className="bd4-b">
                                                    Server ID
                                                    <a
                                                        href="/explanation"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            src="/images/marketBackstage/mark.svg"
                                                            alt="more"
                                                        />
                                                    </a>
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="987325346758"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.GETTINGQUALIFY,
                                                            'serverId',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.gettingQualify
                                                            ?.serverId
                                                    }
                                                />
                                                <p className="bd4-b">
                                                    Server 顯示名稱
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="加密城市實驗室"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.GETTINGQUALIFY,
                                                            'serverName',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.gettingQualify
                                                            ?.serverName
                                                    }
                                                />
                                                <p className="bd4-b">
                                                    Server 連接
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="https://www.cryptocity.tw/"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.GETTINGQUALIFY,
                                                            'DcLink',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.gettingQualify
                                                            ?.DcLink
                                                    }
                                                />
                                            </div>

                                            <div className="bottom">
                                                <h5 className="h5">
                                                    擁有指定 Discord 身份
                                                </h5>
                                                <p className="bd4-b">
                                                    Role ID
                                                    <a
                                                        href="/explanation"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            src="/images/marketBackstage/mark.svg"
                                                            alt="more"
                                                        />
                                                    </a>
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="987325346758"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.GETTINGQUALIFY,
                                                            'qualifyID',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.gettingQualify
                                                            ?.qualifyID
                                                    }
                                                />
                                                <p className="bd4-b">
                                                    Role 顯示標籤
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="加密城市優秀市民"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.GETTINGQUALIFY,
                                                            'qualify',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.gettingQualify
                                                            ?.qualify
                                                    }
                                                />
                                            </div>

                                            {/* 抽獎券加成 */}
                                            {/* <div className="raffle">
                                                <h5 className="h5">
                                                    擁有指定 Discord
                                                    身份特殊加成
                                                </h5>
                                                <h6 className="bd4-n">
                                                    可設定擁有指定 Discord Role
                                                    可獲得額外加成的抽獎券
                                                </h6>
                                                {raffle.map((r, i) => (
                                                    <div
                                                        className="raffle-box"
                                                        key={`raffle-${i}`}
                                                    >
                                                        <div className="first d-flex ai-center jc-between">
                                                            <div>
                                                                <p className="bd4-b">
                                                                    Role ID
                                                                </p>
                                                                <input
                                                                    type="text"
                                                                    placeholder="987325346758"
                                                                    value={
                                                                        mission
                                                                            ?.gettingQualify
                                                                            ?.qualifyID
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <p className="bd4-b">
                                                                    Role
                                                                    顯示標籤
                                                                </p>
                                                                <input
                                                                    type="text"
                                                                    placeholder="加密城市優秀市民"
                                                                    value={
                                                                        mission
                                                                            ?.gettingQualify
                                                                            ?.qualify
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="bd4-b">
                                                                加成抽獎券數
                                                            </p>
                                                            <input
                                                                type="text"
                                                                placeholder="請填寫純數字單位"
                                                                value={
                                                                    mission
                                                                        ?.gettingQualify
                                                                        ?.qualify
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ))}

                                                <button
                                                    className="btn2"
                                                    onClick={() =>
                                                        setRaffle(
                                                            (prev) =>
                                                                prev.concat([1])
                                                            // eslint-disable-next-line function-paren-newline
                                                        )
                                                    }
                                                >
                                                    新增加成Role
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>

                                    {/* twitter */}
                                    <div
                                        className={`twitter-box ${
                                            clickButton.twitter ===
                                            BUTTON_KEY.TWITTERFOLLOW
                                                ? 'act'
                                                : clickButton.twitter ===
                                                  BUTTON_KEY.TWITTERINTERACTION
                                                ? 'act2'
                                                : ''
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/twitter_black.svg"
                                                alt="twitter"
                                            />
                                            是否需要追蹤 Twitter & 與推文互動？
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者在登記項目抽獎前必須與指定
                                            Twitter 帳戶互動
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.TWITTERNONE,
                                                BUTTON_KEY.TWITTERFOLLOW,
                                                BUTTON_KEY.TWITTERINTERACTION,
                                            ].map((v, i) => (
                                                <button
                                                    className={`btn2 ${
                                                        v ===
                                                            clickButton.twitter &&
                                                        'act'
                                                    }`}
                                                    key={`${v}-${i}`}
                                                    onClick={() =>
                                                        onClickBtn('twitter', v)
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="more-box d-block">
                                            <div className="top">
                                                <p className="bd4-b">
                                                    要求抽獎者關注指定帳號
                                                </p>
                                                <div className="d-flex ai-center">
                                                    <div className="img-box">
                                                        <img
                                                            className="cp"
                                                            src="/images/marketBackstage/at2.svg"
                                                            alt="tag"
                                                        />
                                                    </div>
                                                    <div
                                                        className="d-flex ai-center"
                                                        onClick={() =>
                                                            accountRef.current.focus()
                                                        }
                                                    >
                                                        {mission?.followAccount?.account?.map(
                                                            (v, i) => (
                                                                <span
                                                                    key={v}
                                                                    className="bd4-n"
                                                                    onClick={() =>
                                                                        deleteItem(
                                                                            i
                                                                        )
                                                                    }
                                                                >
                                                                    {v.account}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                    <input
                                                        ref={accountRef}
                                                        className={
                                                            showInput
                                                                ? 'act'
                                                                : ''
                                                        }
                                                        type="text"
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key ===
                                                                'Enter'
                                                            ) {
                                                                addMission(
                                                                    WHITELIST_MISSION.FOLLOWACCOUNT,
                                                                    'account',
                                                                    e.target.value.replace(
                                                                        '@',
                                                                        ''
                                                                    )
                                                                );
                                                                e.target.value =
                                                                    '';
                                                                setShowInput(
                                                                    true
                                                                );
                                                                setPlaceholder1(
                                                                    ''
                                                                );
                                                            }
                                                        }}
                                                        placeholder={
                                                            placeholder1
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target
                                                                    .value ===
                                                                ''
                                                            ) {
                                                                setPlaceholder1(
                                                                    '請填寫不包含@的帳號名稱 例：Crypto City'
                                                                );
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            if (
                                                                e.target.value
                                                            ) {
                                                                addMission(
                                                                    WHITELIST_MISSION.FOLLOWACCOUNT,
                                                                    'account',
                                                                    e.target.value.replace(
                                                                        '@',
                                                                        ''
                                                                    )
                                                                );
                                                                e.target.value =
                                                                    '';
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="left">
                                                    <p className="bd4-b">
                                                        要求抽獎者與指定推文互動
                                                    </p>
                                                    <input
                                                        className="bd4-n"
                                                        type="text"
                                                        placeholder="請填寫推文網址"
                                                        onChange={(e) =>
                                                            addMission(
                                                                'twitterLink',
                                                                'link',
                                                                e.target.value
                                                            )
                                                        }
                                                        value={
                                                            mission?.twitterLink
                                                                ?.link
                                                        }
                                                    />
                                                </div>
                                                <div className="right">
                                                    <p className="bd4-b">
                                                        達成指定條件
                                                    </p>
                                                    <div className="d-flex ai-center jc-between">
                                                        {/* 點擊後加 act */}
                                                        {[
                                                            WHITELIST_MISSION.LIKEPOST,
                                                            WHITELIST_MISSION.RETWEET,
                                                            WHITELIST_MISSION.LIKEANDRETWITTE,
                                                        ].map((v, i) => (
                                                            <button
                                                                className={`btn2 ${
                                                                    clickButton.twitterbtn ===
                                                                        v &&
                                                                    'act'
                                                                }`}
                                                                key={`${v}-${i}`}
                                                                onClick={() =>
                                                                    onClickBtn(
                                                                        'twitterbtn',
                                                                        v
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    MISSION_BTN_NAME[
                                                                        v
                                                                    ]
                                                                }
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* instagram */}
                                    {/* <div
                                        className={`instagram-box ${
                                            clickButton.twitter ===
                                            BUTTON_KEY.TWITTERFOLLOW
                                                ? 'act'
                                                : clickButton.twitter ===
                                                  BUTTON_KEY.TWITTERINTERACTION
                                                ? 'act2'
                                                : ''
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/instagram_black.svg"
                                                alt="instagram"
                                            />
                                            是否需要追蹤 Instagram &
                                            與貼文互動？
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者在登記項目抽獎前必須成為您的
                                            Instagram 粉絲或與指定貼文互動
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.TWITTERNONE,
                                                BUTTON_KEY.TWITTERFOLLOW,
                                                BUTTON_KEY.TWITTERINTERACTION,
                                            ].map((v) => (
                                                <button
                                                    className={`btn2 ${
                                                        v ===
                                                            clickButton.twitter &&
                                                        'act'
                                                    }`}
                                                    key={v}
                                                    onClick={() =>
                                                        onClickBtn('twitter', v)
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="more-box d-block">
                                            <div className="top">
                                                <p className="bd4-b">
                                                    要求抽獎者追蹤指定帳號
                                                </p>
                                                <div className="d-flex ai-center">
                                                    <img
                                                        className="cp"
                                                        src="/images/marketBackstage/at.svg"
                                                        alt="tag"
                                                    />
                                                    <div className="d-flex ai-center">
                                                        {mission?.followAccount?.account?.map(
                                                            (v, i) => (
                                                                <span
                                                                    className="bd4-n"
                                                                    onClick={() =>
                                                                        deleteItem(
                                                                            i
                                                                        )
                                                                    }
                                                                >
                                                                    {v.account}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                    <input
                                                        type="text"
                                                        placeholder="請填寫不包含@的帳號名稱 例：Crypto City"
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key ===
                                                                'Enter'
                                                            ) {
                                                                addMission(
                                                                    WHITELIST_MISSION.FOLLOWACCOUNT,
                                                                    'account',
                                                                    e.target
                                                                        .value
                                                                );
                                                                e.target.value =
                                                                    '';
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div
                                                    className="left"
                                                    style={{ width: '100%' }}
                                                >
                                                    <p className="bd4-b">
                                                        要求抽獎者對指定文章按愛心
                                                    </p>
                                                    <input
                                                        className="bd4-n"
                                                        type="text"
                                                        placeholder="請填寫貼文網址"
                                                        onChange={(e) =>
                                                            addMission(
                                                                'twitterLink',
                                                                'link',
                                                                e.target.value
                                                            )
                                                        }
                                                        value={
                                                            mission?.twitterLink
                                                                ?.link
                                                        }
                                                    />
                                                </div>
                                                <div className="right">
                                                    <p className="bd4-b">
                                                        達成指定條件
                                                    </p>
                                                    <div className="d-flex ai-center jc-between">
                                                        {[
                                                            WHITELIST_MISSION.LIKEPOST,
                                                            WHITELIST_MISSION.RETWEET,
                                                            WHITELIST_MISSION.LIKEANDRETWITTE,
                                                        ].map((v) => (
                                                            <button
                                                                className={`btn2 ${
                                                                    clickButton.twitterbtn ===
                                                                        v &&
                                                                    'act'
                                                                }`}
                                                                key={v}
                                                                onClick={() =>
                                                                    onClickBtn(
                                                                        'twitterbtn',
                                                                        v
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    MISSION_BTN_NAME[
                                                                        v
                                                                    ]
                                                                }
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* facebook */}
                                    {/* <div
                                        className={`facebook-box ${
                                            clickButton.facebook ===
                                            BUTTON_KEY.FB_LIKE_PAGE
                                                ? 'act'
                                                : ''
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/facebook_black.svg"
                                                alt="facebook"
                                            />
                                            是否需要按讚 Facebook 粉絲頁？
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者在登記項目抽獎前必須按讚您的
                                            Facebook 粉絲頁
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.FB_NONE,
                                                BUTTON_KEY.FB_LIKE_PAGE,
                                            ].map((v, i) => (
                                                <button
                                                    className={`btn2 ${
                                                        v ===
                                                            clickButton.facebook &&
                                                        'act'
                                                    }`}
                                                    key={`${v}-${i}`}
                                                    onClick={() =>
                                                        onClickBtn(
                                                            'facebook',
                                                            v
                                                        )
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="more-box d-block">
                                            <div className="top">
                                                <p className="bd4-b">
                                                    要求抽獎者按讚粉絲頁
                                                </p>
                                                <div className="d-flex ai-center">
                                                    <div
                                                        className="bd4-n d-flex ai-center"
                                                        style={{
                                                            paddingLeft: '20px',
                                                        }}
                                                    ></div>
                                                    <input
                                                        type="text"
                                                        placeholder="請填寫粉絲頁token, 例如：https://www.facebook.com/CryptoCitytw/，請輸入「CryptoCitytw」"
                                                        onChange={(e) =>
                                                            addMission(
                                                                WHITELIST_MISSION.LIKE_FB_PAGE,
                                                                'likeFbPageToken',
                                                                e.target.value
                                                            )
                                                        }
                                                        value={
                                                            mission[
                                                                WHITELIST_MISSION
                                                                    .LIKE_FB_PAGE
                                                            ]?.likeFbPageToken
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* 錢包 */}
                                    {/* 加 act 顯示 所需金額 */}
                                    <div
                                        className={`wallet-box ${
                                            clickButton.wallet ===
                                                BUTTON_KEY.WALLETPRICE && 'act'
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/wallet_black.svg"
                                                alt="wallet"
                                            />
                                            要求抽獎者錢包擁有最低金額
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者在登記項目抽獎時錢包必須持有指定金額，若低於指定金額，將自動取消獲獎資格
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.WALLETNONE,
                                                BUTTON_KEY.WALLETPRICE,
                                            ].map((v, i) => (
                                                <button
                                                    className={`btn2 ${
                                                        clickButton.wallet ===
                                                            v && 'act'
                                                    }`}
                                                    key={`${v}-${i}`}
                                                    onClick={() =>
                                                        onClickBtn('wallet', v)
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="more-box">
                                            <div className="top">
                                                <p className="bd4-b">
                                                    所需的ETH金額
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="所需的ETH金額"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.CHECKWALLET,
                                                            'min',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.checkWallet
                                                            ?.min
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* NFT */}
                                    {/* 加 act 顯示 指定ＮＦＴ */}
                                    <div
                                        className={`NFT-box ${
                                            clickButton.NFT ===
                                                BUTTON_KEY.NFTREQUIRE && 'act'
                                        }  ${
                                            project.chainType === 'SOL'
                                                ? 'd-none'
                                                : ''
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/NFT_black.svg"
                                                alt="NFT"
                                            />
                                            要求抽獎者錢包持有指定NFT
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者在登記項目抽獎時錢包必須持有指定NFT，否則將自動取消獲獎資格。
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.NFTNONE,
                                                BUTTON_KEY.NFTREQUIRE,
                                            ].map((v) => (
                                                <button
                                                    className={`btn2 ${
                                                        v === clickButton.NFT &&
                                                        'act'
                                                    }`}
                                                    onClick={() =>
                                                        onClickBtn('NFT', v)
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="more-box">
                                            <div className="top">
                                                <p className="bd4-b">
                                                    NFT 名稱
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="Genesis Box"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.ChECKNFT,
                                                            'NFTName',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.checkNFT
                                                            ?.NFTName
                                                    }
                                                />
                                                <p className="bd4-b">
                                                    Opensea NFT 網址
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="https://opensea.io/collection/genesis-box"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.ChECKNFT,
                                                            'NFT',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.checkNFT?.NFT
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* question */}
                                    {/* 加 act 顯示 指定問題 */}
                                    <div
                                        className={`question-box ${
                                            clickButton.QA === BUTTON_KEY.QA &&
                                            'act'
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/question_black.svg"
                                                alt="NFT"
                                            />
                                            要求抽獎者回答內容
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者填寫自定義的問題回答
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.QANONE,
                                                BUTTON_KEY.QA,
                                            ].map((v, i) => (
                                                <button
                                                    className={`btn2 ${
                                                        v === clickButton.QA &&
                                                        'act'
                                                    }`}
                                                    key={`${v}-${i}`}
                                                    onClick={() =>
                                                        onClickBtn('QA', v)
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="more-box">
                                            <div className="top">
                                                <p className="bd4-b">
                                                    請描述問題（500字元內）
                                                </p>
                                                <input
                                                    type="text"
                                                    maxLength={500}
                                                    placeholder="填寫問題內容"
                                                    onChange={(e) =>
                                                        addMission(
                                                            WHITELIST_MISSION.QA,
                                                            'question',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        mission?.[
                                                            WHITELIST_MISSION.QA
                                                        ]?.question
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* 閱讀文章 */}
                                    {/* 加 act 顯示 指定文章 */}
                                    {/* <div
                                        className={`read-box
                                        ${
                                            clickButton.READ_NEWS ===
                                                BUTTON_KEY.READ_NEWS && 'act'
                                        }`}
                                    >
                                        <h2 className="h3">
                                            <img
                                                src="/images/marketBackstage/read_black.svg"
                                                alt="NFT"
                                            />
                                            要求抽獎者閱讀文章
                                        </h2>
                                        <p className="bd4-n">
                                            要求抽獎者閱讀指定文章內容
                                        </p>
                                        <div>
                                            {[
                                                BUTTON_KEY.READ_NONE,
                                                BUTTON_KEY.READ_NEWS,
                                            ].map((v) => (
                                                <button
                                                    className={`btn2 ${
                                                        v ===
                                                            clickButton.READ_NEWS &&
                                                        'act'
                                                    }`}
                                                    key={v}
                                                    onClick={() =>
                                                        onClickBtn(
                                                            'READ_NEWS',
                                                            v
                                                        )
                                                    }
                                                >
                                                    {BUTTON_MAP[v]}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="more-box d-block">
                                            <div className="top">
                                                <p className="bd4-b">
                                                    請選擇文章
                                                </p>
                                                <div className="article">
                                                    <img
                                                        className={
                                                            showArticle && 'act'
                                                        }
                                                        src="/images/marketBackstage/down_arrow.svg"
                                                        alt="arrow"
                                                    />
                                                    <input
                                                        placeholder="請輸入文章ID或關鍵字"
                                                        value={article}
                                                        onFocus={() =>
                                                            setShowArticle(true)
                                                        }
                                                        onBlur={() =>
                                                            setShowArticle(
                                                                false
                                                            )
                                                        }
                                                    />
                                                    <ul
                                                        className={`d-flex flex-wrap jc-start ${
                                                            showArticle && 'act'
                                                        }`}
                                                    >
                                                        <li>文章1</li>
                                                        <li>文章2</li>
                                                        <li>文章3</li>
                                                        <li>文章4</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        {/* 項目基本資訊 */}
                        <div
                            className={`lot-info ${step === 5 && 'act'}`}
                            onClick={() => setStep(5)}
                        >
                            <h2 className="bd4-b">項目基本資訊</h2>
                            <div className="inner">
                                <h6 className="bd4-b must">抽獎名稱</h6>
                                <input
                                    className="bd4-n"
                                    type="text"
                                    placeholder="請填寫本次計劃抽獎的名稱標題"
                                    onChange={(e) =>
                                        onChange('title', e.target.value)
                                    }
                                    value={form.title}
                                />
                                <h6 className="bd4-b must">兌換獎項</h6>
                                <input
                                    type="text"
                                    className="bd4-n"
                                    placeholder="請填寫單一獎項名稱，例：The Hootis 白名單"
                                    onChange={(e) =>
                                        onChange('award', e.target.value)
                                    }
                                    value={form.award}
                                />

                                <h6 className="bd4-b must">兑獎方式</h6>
                                <div className="accept-box d-flex ai-center jc-between">
                                    {Object.keys(ACCEPT_TYPE).map((v, i) => (
                                        <button
                                            className={
                                                v === form.acceptType
                                                    ? 'act'
                                                    : ''
                                            }
                                            key={`${v}-${i}`}
                                            onClick={(e) =>
                                                onChange('acceptType', v)
                                            }
                                        >
                                            {ACCEPT_TYPE[v]}
                                        </button>
                                    ))}
                                </div>

                                <h6 className="bd4-b">鑄造日期</h6>
                                <input
                                    className={`bd4-n ${hasTime ? 'act' : ''}`}
                                    type="date"
                                    placeholder="如果尚未確定，可以留白，系統將顯示TBD"
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setHasTime(false);
                                        } else {
                                            setHasTime(true);
                                        }
                                        onChange('mintTime', e.target.value);
                                    }}
                                    value={
                                        form.mintTime &&
                                        moment(form.mintTime).format(
                                            'YYYY-MM-DD'
                                        )
                                    }
                                />

                                <h6 className="bd4-b">發行數量</h6>
                                <input
                                    className="bd4-n"
                                    type="text"
                                    placeholder="如果尚未確定，可以留白，系統將顯示TBD"
                                    onChange={(e) =>
                                        onChange('issue', e.target.value)
                                    }
                                    value={form.issue}
                                />

                                <h6 className="bd4-b">鑄造價格</h6>
                                <div className="price-box">
                                    <button
                                        className={`btn2 ${
                                            form.chainType === 'ETH'
                                                ? 'act'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            onChange('chainType', 'ETH')
                                        }
                                    >
                                        ETH
                                    </button>
                                    <button
                                        className={`btn2 ${
                                            form.chainType === 'SOL'
                                                ? 'act'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            onChange('chainType', 'SOL')
                                        }
                                    >
                                        Solana
                                    </button>
                                </div>
                                <input
                                    className="bd4-n"
                                    type="text"
                                    placeholder="請填寫價格"
                                    onChange={(e) =>
                                        onChange('mintPrice', e.target.value)
                                    }
                                    value={form.mintPrice}
                                />

                                <h6 className="bd4-b must">Twitter</h6>
                                <input
                                    className="bd4-n"
                                    type="text"
                                    placeholder="請輸入網址"
                                    onChange={(e) =>
                                        onChange('twitterLink', e.target.value)
                                    }
                                    value={form.twitterLink}
                                />

                                <h6 className="bd4-b">官方網站</h6>
                                <input
                                    className="bd4-n"
                                    type="text"
                                    placeholder="請輸入網址"
                                    onChange={(e) =>
                                        onChange('officalLink', e.target.value)
                                    }
                                    value={form.officalLink}
                                />

                                <h6 className="bd4-b">Discord</h6>
                                <input
                                    className="bd4-n"
                                    type="text"
                                    placeholder="請輸入網址"
                                    onChange={(e) =>
                                        onChange('discordLink', e.target.value)
                                    }
                                    value={form.discordLink}
                                />
                            </div>
                        </div>

                        {/* 其他內容 */}
                        <div
                            className={`lot-others ${step === 6 && 'act'}`}
                            onClick={() => setStep(6)}
                        >
                            <h2 className="bd4-b">其他內容</h2>
                            <div className="inner">
                                <textarea
                                    placeholder="請詳細描述您要變更的事項以及原因"
                                    onChange={(e) =>
                                        onChange('desc', e.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="btns-box">
                            <button
                                className="btn2"
                                onClick={() => setProject(null)}
                            >
                                取消
                            </button>
                            <button
                                className="btn2"
                                onClick={() => checkMission()}
                            >
                                送出申請
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`apply-wrapper ${!step ? '' : 'd-none'}`}>
                    <div>
                        {/* email確認 */}
                        {!saveSuccessly && (
                            <div className="email">
                                <p className="bd4-b">
                                    請填寫聯繫Email
                                    {hintEmail && (
                                        <span>請輸入正確Email格式</span>
                                    )}
                                </p>
                                <input
                                    className="bd4-n"
                                    type="email"
                                    placeholder="1234@gmail.com"
                                    onChange={(e) => {
                                        if (
                                            /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gm.test(
                                                e.target.value
                                            )
                                        ) {
                                            setHintEmail(false);
                                        } else {
                                            setHintEmail(true);
                                        }
                                        onChange('email', e.target.value);
                                    }}
                                />
                                <div className="btns">
                                    <button
                                        className="btn2"
                                        onClick={() => setProject(null)}
                                    >
                                        取消
                                    </button>
                                    {/* 加 act 變藍 */}
                                    <button
                                        className={`btn2 ${
                                            form.email && !hintEmail && 'act'
                                        }`}
                                        disabled={!form.email || hintEmail}
                                        onClick={() => onSubmit()}
                                    >
                                        完成申請
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className={`send ${!saveSuccessly && 'd-none'}`}>
                            <h4 className="h4">申請修正已送出</h4>
                            <p className="bd4-n">
                                感謝您的申請，負責同仁在確認需求後，會透過電子郵件與您聯繫，請留意您的信箱，謝謝。
                            </p>
                            <button
                                className="btn2"
                                onClick={() => {
                                    setProject(null);
                                    setSaveSuccessly(false);
                                }}
                            >
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}
